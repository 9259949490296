import React, { useState, useEffect } from 'react';
import { useAuth0 } from "../../react-auth0-wrapper";
import theme from '../../themes/theme';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ListItem, Divider } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import TextField from '@material-ui/core/TextField';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import appPaths from '../../applicationPaths.json'

export default function JobPdfList(props) {
    const [contentLoading, setContentLoading] = useState(true);
    const [JobPdfList, setJobPdfList] = useState([]);
    const { isAuthenticated, loginWithRedirect, logout, getTokenSilently, user, loading } = useAuth0();
    const [editMode, setEditMode] = useState(false);

    const openPdf = (e, jobPdf) => {
        e.stopPropagation();
        window.open(jobPdf);

    }

    const convertTime = (time) => {
        time = time.split(':'); // convert to array

        // fetch
        var hours = Number(time[0]);
        var minutes = Number(time[1]);
        var seconds = Number(time[2]);
        
        // calculate
        var timeValue;
        
        if (hours > 0 && hours <= 12) {
          timeValue= "" + hours;
        } else if (hours > 12) {
          timeValue= "" + (hours - 12);
        } else if (hours == 0) {
          timeValue= "12";
        }
         
        timeValue += (minutes < 10) ? ":0" + minutes : ":" + minutes;  // get minutes
        //timeValue += (seconds < 10) ? ":0" + seconds : ":" + seconds;  // get seconds
        timeValue += (hours >= 12) ? " PM" : " AM";  // get AM/PM

        return timeValue;
    }

    const convertDate = (date) => {
        let month_names = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let d = new Date(date);
        let month = month_names[d.getMonth()];
        let day = date.split('/')[1];
        let year = date.split('/')[2];

        return month + ' ' + day + ', ' + year;
    }

    const saveNotes = async (id, note) => {
        try {
          const token = await getTokenSilently();
          const response = await fetch(appPaths.apiPath + "/api/saveInvoiceNotes", {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',  // sent request
              'Accept':       'application/json'   // expected data sent back
            },
            body: JSON.stringify({recordId: id, notes : note})
          });
         
        } catch (error) {
            console.error(error);
        }
      };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.target.blur();
        }
    }

    const getDecodedUrl = async (url) => {
      const path = appPaths.apiPath + '/api/create/pdf_url'
      const decodedUrl = decodeURIComponent(url)
      console.log("url: " + url)
      const request = {
          method: 'POST',
          headers: { 
              Accept: 'application/json'
          },
          body: JSON.stringify({pdf_path: decodedUrl})
      };
      const result = await fetch(path, request);
      let obj = await result.json();
      console.log("path: " + decodeURIComponent(obj.pdf_path))
      window.open(decodeURIComponent(obj.pdf_path), '_blank')
     
      // return "https://google.com"
  }

    useEffect(() => {
        const callApi = async () => {
            try {
              const token = await getTokenSilently();
              const response = await fetch(appPaths.apiPath + "/api/getJobPdfList", {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',  // sent request
                  'Accept':       'application/json'   // expected data sent back
                },
                body: JSON.stringify({jobId: props.__ID})
              });
              const responseData = await response.json();
              if(responseData.message) {
                console.log(responseData.data);
                setJobPdfList(responseData.data);
                setContentLoading(false);
              }
              else {
                setJobPdfList([]);
                setContentLoading(false);
              }
            } catch (error) {
              console.error(error);
            }
        };

        callApi();
    }, [getTokenSilently, props.__ID]);

    const deleteJobPdf = async (pdfId) => {
      if( window.confirm('Are you sure you want to delete this Job PDF?') ) {
        setJobPdfList(prevState => {
          return prevState.filter(item => {
              return item.fieldData.__ID !== pdfId;
          });
        });
        try {
          const token = await getTokenSilently();
          const response = await fetch(appPaths.apiPath + "/api/deleteJobPdf", {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',  // sent request
              'Accept':       'application/json'   // expected data sent back
            },
            body: JSON.stringify({__ID: pdfId})
          });
          const responseData = await response.json();
        } catch (error) {
          console.error(error);
        }
      }
    };

    const getSecondaryText = (jobPdf) => { 
      var filename = jobPdf.fieldData.FileName_ae;
      var datetime = jobPdf.fieldData.z_Modification_TS;
      return (
        <>
        {filename}
        <br/>
        {datetime}
        </>
      );
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width:'100%'}}>
            {!contentLoading &&
            <>
                <Card style={{width:'100%', display: 'flex', textAlign: 'center', flexDirection:'column'}}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Related Job PDFs</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <List style={{width:'100%'}}>
                                <Divider />
                                {JobPdfList.map(jobPdf => (
                                    <div key={jobPdf.fieldData.__ID}>
                                        <ListItem button disableRipple>
                                            <ListItemText button onClick={e => {getDecodedUrl(jobPdf.fieldData.S3Location.replace('https://duraroof-app.s3.amazonaws.com/', ''))}} primary={jobPdf.fieldData.Number_INV } secondary={getSecondaryText(jobPdf)}/>
                                            
                                            {editMode && <ListItemIcon>
                                                <IconButton label="Save" onClick={e => { e.stopPropagation(); setEditMode(false); }}>
                                                    <DoneIcon />
                                                </IconButton>
                                            </ListItemIcon>}
                                            {jobPdf.fieldData.S3Location && <ListItemIcon>
                                                <IconButton onClick={e => {getDecodedUrl(jobPdf.fieldData.S3Location.replace('https://duraroof-app.s3.amazonaws.com/', ''))}}>
                                                    <PictureAsPdfIcon />
                                                </IconButton>
                                            </ListItemIcon>}
                                            {<ListItemIcon>
                                                <IconButton onClick={e => deleteJobPdf(jobPdf.fieldData.__ID)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemIcon>}


                                        </ListItem>
                                        <Divider />
                                    </div>
                                ))}
                            </List>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    
                </Card>
            </>
            }
            {contentLoading && <div style={{width:'100%', display: 'flex', textAlign: 'center', justifyContent:'center', borderRadius: '0px'}}><CircularProgress></CircularProgress></div>}

        </div>
    );

}