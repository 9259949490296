import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import MobileStepper from '@material-ui/core/MobileStepper'
import Button from '@material-ui/core/Button'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import InvoiceMarkers from './InvoiceMarkers'
import InvoiceImages from './InvoiceImages'
import InvoiceOverview from './InvoiceOverview'
import InvoiceMaterials from './InvoiceMaterials'
import InvoiceAccounting from './InvoiceAccounting'
import {
  Stepper,
  Step,
  StepButton,
  Typography,
  Paper,
  CircularProgress
} from '@material-ui/core'
import { useAuth0 } from '../../../react-auth0-wrapper'
import appPaths from '../../../applicationPaths.json'
import { Redirect } from 'react-router'

const useStyles = makeStyles(theme => ({
  stepper: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    borderRadius: '0px',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  invoice_wrapper: {
    width: '100%'
  },
  invoice_innerWrapper: {
    width: '100%',
    textAlign: 'center',
    backgroundColor: 'white',
    color: 'black',
    padding: '15px'
  },
  invoice_heading: {
    width: '100%',
    textAlign: 'center'
  }
}))

function getSteps () {
  return ['Markers', 'Images', 'Materials', 'Create Invoice', 'Review']
}

export default function Invoice (props) {
  const classes = useStyles()
  const theme = useTheme()
  const [activeStep, setActiveStep] = useState(0)
  const steps = getSteps()
  const [selectedImages, setSelectedImages] = useState([])
  const [images, setImages] = useState([])
  const [imageMapData, setImageMapData] = useState([])
  const [mapRef, setMapRef] = useState(null)
  const [center, setCenter] = useState(null)
  const [zoom, setZoom] = useState(21)
  const { getTokenSilently, user } = useAuth0()
  const [invoiceLoading, setInvoiceLoading] = useState(-1)
  const [materialList, setMaterialList] = useState([
    { name: '', price: '', qty: '', units: '' },
    { name: '', price: '', qty: '', units: '' },
    { name: '', price: '', qty: '', units: '' }
  ])
  const [trips, setTrips] = useState([])
  const [miscItems, setMiscItems] = useState([
    { name: 'Admin Fee', price: '24.50', qty: '0' }
  ])
  const [redirectDetails, setRedirectDetails] = useState({
    set: false,
    location: '/job'
  })
  const [features, setFeatures] = useState([])
  const [allFeatures, setAllFeatures] = useState([])
  const [permissionType, setPermissionType] = useState([])
  const [laborData, setLaborData] = useState([])
  const [jobDetailsLoaded, setJobDetailsLoaded] = useState(false)
  const [salesperson, setSalesperson] = useState('')
  const [taxChecked, setTaxChecked] = useState(false)
  const [goBack, setGoBack] = useState(false)
  const [invoiceContentLoading, setInvoiceContentLoading] = useState(true)
  const [description, setDescription] = useState('')
  const [url, setUrl] = useState('')
  const [oldMarkers, setOldMarkers] = useState([])
  const [oldPictures, setOldPictures] = useState([])
  const [oldMaterials, setOldMaterials] = useState([])
  const [invoiceId, setInvoiceId] = useState([])
  const [jobDetails, setJobDetails] = useState(null)
  const [timecards, setTimecards] = useState([])
  const [timecardsLoaded, setTimecardsLoaded] = useState(false)

  useEffect(() => {
    const callApi = async () => {
      try {
        const token = await getTokenSilently()
        const response = await fetch(appPaths.apiPath + '/api/getInvoiceData', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json', // sent request
            Accept: 'application/json' // expected data sent back
          },
          body: JSON.stringify({ id: props.location.state.invoiceId })
        })
        const responseData = await response.json()

        console.log(responseData)
        if (responseData.message) {
          setDescription(responseData.data.invoice.fieldData.Description)
          let markers = responseData.data.invoice.fieldData.invoice_features
          setOldMarkers(markers)

          let pictures = responseData.data.invoiceItems
            .filter(item => item.fieldData._idf_picture !== '')
            .map(image => image.fieldData._idf_picture)
          setOldPictures(pictures)

          let materials = responseData.data.invoiceItems.filter(
            item =>
              item.fieldData.pictureSectionMaterial_flag !== '' &&
              item.fieldData.TruckTrip_flag !== '1' &&
              item.fieldData.LaborData_flag !== '1' &&
              item.fieldData.MiscItem_flag !== '1' &&
              item.fieldData.MaterialTotal_flag !== '1'
          )

          setOldMaterials(materials)

          let trips = responseData.data.invoiceItems
            .filter(item => item.fieldData.TruckTrip_flag !== '')
            .map(trip => ({
              name: 'Truck Trips',
              price: trip.fieldData.UnitPrice,
              qty: trip.fieldData.Qty,
              direct_cost: trip.fieldData.DirectCost
            }))

          if (trips.length > 0) {
            setTrips(trips)
          }

          let misc = responseData.data.invoiceItems
            .filter(item => item.fieldData.MiscItem_flag !== '')
            .map(miscItem => ({
              name: miscItem.fieldData.Item,
              price: miscItem.fieldData.UnitPrice,
              qty: miscItem.fieldData.Qty,
              direct_cost: miscItem.fieldData.DirectCost
            }))

          if (misc.length > 0) {
            setMiscItems(misc)
          }

          let labor = responseData.data.invoiceItems
            .filter(item => item.fieldData.LaborData_flag !== '')
            .map(laborItem => ({
              description: laborItem.fieldData.Item,
              rate: laborItem.fieldData.UnitPrice,
              hours: laborItem.fieldData.Qty,
              direct_cost: laborItem.fieldData.DirectCost
            }))

          if (labor.length > 0) {
            setLaborData(labor)
          }

          if (responseData.data.invoice.fieldData._flag_showTax == '1') {
            setTaxChecked(true)
          }

          setInvoiceId(responseData.data.invoice.fieldData.__ID)

          setInvoiceContentLoading(false)
          //setJobDetails(responseData.data.response.data[0].fieldData);
          //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
        } else {
          setInvoiceContentLoading(false)
          //alert(responseData);
        }
      } catch (error) {
        console.error(error)
      }
    }
    callApi()
  }, [getTokenSilently, props])

  useEffect(() => {
    console.log(allFeatures)
    if (oldMarkers.length > 0 && allFeatures.length > 0) {
      setFeatures(
        allFeatures.filter(feature =>
          oldMarkers.includes(feature.getProperty('id'))
        )
      )
    }
  }, [oldMarkers, allFeatures])

  useEffect(() => {
    const getPermissions = async () => {
      try {
        const token = await getTokenSilently()
        const response = await fetch(
          appPaths.apiPath + '/api/getUserPermissions',
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )

        const responseData = await response.json()
        setPermissionType(responseData)
      } catch (error) {
        console.error(error)
      }
    }
    getPermissions()
  }, [getTokenSilently, user])

  useEffect(() => {
    if (!jobDetailsLoaded) {
      const callApi = async () => {
        try {
          const token = await getTokenSilently()
          const response = await fetch(
            appPaths.apiPath + '/api/getJobDetails',
            {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json', // sent request
                Accept: 'application/json' // expected data sent back
              },
              body: JSON.stringify({ id: props.location.state.__ID })
            }
          )

          const responseData = await response.json()
          if (responseData.message) {
            setJobDetailsLoaded(true)
            setSalesperson(
              responseData.data.response.data[0].fieldData.Salesperson
            )
            setJobDetails(responseData.data.response.data[0].fieldData)
          } else {
            //alert(responseData);
          }
        } catch (error) {
          console.error(error)
          setJobDetailsLoaded(false)
        }
      }
      callApi()
    }
  }, [getTokenSilently, props.location.state.__ID, jobDetailsLoaded])

  useEffect(() => {
    if (!timecardsLoaded) {
      const callApi = async () => {
        try {
          const token = await getTokenSilently()
          const response = await fetch(appPaths.apiPath + '/api/getTimecards', {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json', // sent request
              Accept: 'application/json' // expected data sent back
            },
            body: JSON.stringify({ id: props.location.state.__ID })
          })

          const responseData = await response.json()

          if (responseData.message) {
            setTimecardsLoaded(true)
            setTimecards(responseData.data.response.data)
          } else {
          }
        } catch (error) {
          console.error(error)
          setTimecardsLoaded(false)
        }
      }

      callApi()
    }
  }, [getTokenSilently, props.location.state.__ID, timecardsLoaded])

  function handleNext () {
    /*if(activeStep == 0)
      {
        html2canvas(document.getElementById('googleMarkerMap'), {
          useCORS: true,
      allowTaint: false,
      ignoreElements: (node) => {
        return node.nodeName === 'IFRAME';
      }
        }).then(function(canvas) {
          //console.log(canvas);
          document.getElementById('mapImage').appendChild(canvas);
      });
      }
*/
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  function handleBack () {
    if (activeStep === 0) {
      let data = {
        set: true,
        to: { pathname: '/job', state: { __ID: props.location.state.__ID } }
      }
      setRedirectDetails(data)
      setGoBack(true)
    } else {
      setActiveStep(prevActiveStep => prevActiveStep - 1)
    }
  }

  function addImages (index) {
    setSelectedImages(prevState => {
      return [
        ...images.filter(image => image.fieldData._idf_marker == index),
        ...prevState
      ]
    })
  }
  function removeImages (index) {
    setSelectedImages(prevState => {
      return [
        ...prevState.filter(image => image.fieldData._idf_marker != index)
      ]
    })
  }

  const createInvoice = (mapUrl, description, type) => {
    setInvoiceLoading(0)
    var bodyData = {}
    bodyData.mapUrl = mapUrl
    bodyData.description = description
    bodyData.selectedImages = selectedImages
    bodyData._idf_job = props.location.state.__ID
    bodyData.materialList = materialList.filter(
      material => material.name !== ''
    )
    bodyData.laborData = laborData
    bodyData.type = type
    bodyData.salesperson = salesperson
    bodyData.features = features.map(feature => feature.getProperty('id'))
    bodyData.showTax = taxChecked === true ? 1 : ''
    bodyData.totalCost = total()
    if (trips[0].qty != '0') {
      bodyData.trips = trips
    }
    bodyData.__ID = invoiceId
    bodyData.miscItems = miscItems

    createNewInvoice(bodyData)
  }
  const createNewInvoice = async body => {
    try {
      const token = await getTokenSilently()
      const response = await fetch(appPaths.apiPath + '/api/editInvoice', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json', // sent request
          Accept: 'application/json' // expected data sent back
        },
        body: JSON.stringify(body)
      })

      const responseData = await response.json()
      //console.log(responseData);
      if (responseData.message) {
        if (permissionType.includes('type:admin')) {
          window.open(responseData.invoice.url)
        }
        setInvoiceLoading(1)
        let data = {
          set: true,
          to: { pathname: '/job', state: { __ID: props.location.state.__ID } }
        }
        setRedirectDetails(data)
      } else {
        setInvoiceLoading(2)
        //alert(responseData);
      }
    } catch (error) {
      console.error(error)
    }
  }

  const totalDirectCost = () => {
    let total = 0
    laborData.forEach(row => {
      total += lineTotal(row.hours, row.direct_cost)
    })

    materialList.forEach(row => {
      total += lineTotal(row.qty, row.direct_cost)
    })

    trips.forEach(row => {
      total += lineTotal(row.qty, row.direct_cost)
    })

    miscItems.forEach(row => {
      total += lineTotal(row.qty, row.direct_cost)
    })

    return total
  }

  const total = () => {
    let total = 0
    laborData.forEach(row => {
      total += lineTotal(row.hours, row.rate)
    })

    materialList.forEach(row => {
      total += lineTotal(row.price, row.qty)
    })

    trips.forEach(row => {
      total += lineTotal(row.price, row.qty)
    })

    miscItems.forEach(row => {
      total += lineTotal(row.price, row.qty)
    })

    return total
  }

  const lineTotal = (quantity, cost) => {
    if (
      !quantity ||
      quantity === '' ||
      isNaN(quantity) ||
      !cost ||
      cost === '' ||
      isNaN(cost)
    ) {
      return 0
    } else {
      let total = parseFloat(quantity) * parseFloat(cost)
      return total
    }
  }

  return (
    <div className={classes.invoice_wrapper}>
      {goBack && <Redirect push to={redirectDetails.to} />}
      {!invoiceContentLoading && (
        <>
          {invoiceLoading === -1 && (
            <>
              <div className={classes.invoice_innerWrapper}>
                {jobDetails !== null &&
                  jobDetails.Customer_lu +
                    ' - ' +
                    jobDetails.Job_Name +
                    ' - ' +
                    jobDetails.Number_JobCombined}
              </div>
              <MobileStepper
                variant='dots'
                steps={steps.length}
                position='static'
                activeStep={activeStep}
                className={classes.root}
                nextButton={
                  <Button
                    size='small'
                    onClick={handleNext}
                    disabled={activeStep === 4}
                  >
                    Next
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                }
                backButton={
                  <Button size='small' onClick={handleBack}>
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    Back
                  </Button>
                }
              />
              <Stepper
                activeStep={activeStep}
                className={classes.stepper}
                alternativeLabel
              >
                {steps.map((label, i) => (
                  <Step
                    key={label}
                    onClick={() => {
                      setActiveStep(i)
                    }}
                  >
                    <StepButton>{label}</StepButton>
                  </Step>
                ))}
              </Stepper>
              <Paper className={classes.sectionMobile}>
                <Typography variant='h5' className={classes.invoice_heading}>
                  {getSteps()[activeStep]}
                </Typography>
              </Paper>

              <div style={{ display: activeStep == 0 ? 'flex' : 'none' }}>
                <InvoiceMarkers
                  allFeatures={allFeatures}
                  setAllFeatures={setAllFeatures}
                  features={features}
                  setFeatures={setFeatures}
                  setZoom={setZoom}
                  setCenter={setCenter}
                  setMapRef={setMapRef}
                  setImageMapData={setImageMapData}
                  addImages={addImages}
                  removeImages={removeImages}
                  selectedImages={selectedImages}
                  jobId={props.location.state.__ID}
                />
              </div>
              <div style={{ display: activeStep == 1 ? 'flex' : 'none' }}>
                <InvoiceImages
                  imageMapData={imageMapData}
                  images={images}
                  changeImages={setImages}
                  changeSelectedImages={val => setSelectedImages(val)}
                  selectedImages={selectedImages}
                  jobId={props.location.state.__ID}
                  oldPictures={oldPictures}
                />
              </div>
              <div style={{ display: activeStep == 2 ? 'flex' : 'none' }}>
                <InvoiceMaterials
                  materialList={materialList}
                  setMaterialList={setMaterialList}
                  zoom={zoom}
                  tab={activeStep}
                  center={center}
                  mapData={imageMapData}
                  selectedImages={selectedImages}
                  mapRef={mapRef}
                  jobId={props.location.state.__ID}
                  oldMaterials={oldMaterials}
                />
              </div>
              <div style={{ display: activeStep == 3 ? 'flex' : 'none' }}>
                <InvoiceAccounting
                  total={total}
                  lineTotal={lineTotal}
                  totalDirectCost={totalDirectCost}
                  materialList={materialList}
                  setMaterialList={setMaterialList}
                  taxChecked={taxChecked}
                  setTaxChecked={setTaxChecked}
                  trips={trips}
                  setTrips={setTrips}
                  miscItems={miscItems}
                  setMiscItems={setMiscItems}
                  timecards={timecards}
                  setTimecards={setTimecards}
                  laborData={laborData}
                  setLaborData={setLaborData}
                  zoom={zoom}
                  tab={activeStep}
                  center={center}
                  mapData={imageMapData}
                  selectedImages={selectedImages}
                  mapRef={mapRef}
                  jobId={props.location.state.__ID}
                />
              </div>
              <div style={{ display: activeStep == 4 ? 'flex' : 'none' }}>
                <InvoiceOverview
                  description={description}
                  url={url}
                  setDescription={setDescription}
                  setUrl={setUrl}
                  features={features}
                  createInvoice={createInvoice}
                  zoom={zoom}
                  tab={activeStep}
                  center={center}
                  mapData={imageMapData}
                  selectedImages={selectedImages}
                  mapRef={mapRef}
                  jobId={props.location.state.__ID}
                />
              </div>
            </>
          )}
          {invoiceLoading === 0 && <CircularProgress></CircularProgress>}
          {invoiceLoading === 1 && redirectDetails.set && (
            <Redirect push to={redirectDetails.to} />
          )}
          {invoiceLoading === 2 && (
            <div>Invoice Creation Failed. Please Try again</div>
          )}
        </>
      )}
    </div>
  )
}
