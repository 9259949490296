import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgress } from '@material-ui/core';

export default function ReturnJobDialog(props) {
  const {open, setOpen} = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleReturnJob = () => {
    props.returnJob();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Return Job</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{width:"100%"}}>
              {!props.returningJob && <>
                Set Job Status to "Return"?
              </>}
              {props.returningJob && <div style={{width: '100%', textAlign: 'center'}}><CircularProgress /></div>}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Do Not
          </Button>
          <Button onClick={handleReturnJob} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}