import React, { useState, useEffect } from 'react';
import { useAuth0 } from "../../react-auth0-wrapper";
import { Paper, Button, MenuItem, OutlinedInput, Select, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Camera from 'react-html5-camera-photo';
import ReplayIcon from '@material-ui/icons/Replay';
import appPaths from '../../applicationPaths.json';
import { Redirect } from 'react-router';
import TextField from '@material-ui/core/TextField';
import InvoiceMaterialUnitsDropdown from "../Invoice/editInvoice/InvoiceMaterialUnitDropdown";

const useStyles = makeStyles(theme => ({
    jobFullWidth: {
        width: '100%',
    },
    jobCameraContainer: {
      width: '100%',
      display: 'flex',
      justifyContent:'center',
      flexDirection: 'column',
      textAlign: 'center'
    },

    jobSpacing: {
      padding: '0 8px',
      marginBottom: '2%'
    },

    jobCameraMargin: {
      margin:'16px'
    },

    jobPosition: {
      position:'relative'
    },

    jobCameraIcon: {
      position: 'absolute',
      right: '0',
      top: '0'
    }

  }));

export default function JobCamera (props) {
    const classes = useStyles();
    const [images, setImages] = useState([]);
    const [mirror, setMirror] = useState(false);
    const [idealFacingMode, setIdealFacingMode] = useState('environment');
    const [showImages, setShowImages] = useState(0);
    const [caption, setCaption] = useState('');
    const { getTokenSilently } = useAuth0();
    const [uploading, setUploading] = useState(false);
    const [open, setOpen] = useState(false);
    const [newImages, setNewImages] = useState(0);
    const [redirectDetails, setRedirectDetails] = useState({set: false, location: "/job-map"});
    const [values, setValues] = useState({
      beforeAfter: 'Before',
      id:''
    });
    const [captionList, setCaptionList] = useState([]);

    useEffect(() => {
      const getCaptions = async () => {
        try {
          const token = await getTokenSilently();
          const response = await fetch(appPaths.apiPath + "/api/getCaptionList", {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',  // sent request
              'Accept':       'application/json'   // expected data sent back
            },
          });
          const responseData = await response.json();
          console.log(responseData.data);
          if(responseData.message)
          {
            setCaptionList(responseData.data.response.data);
          }
          else
          {
            //alert(responseData);
          }
        } catch (error) {
          console.error(error);
        }
      };
      getCaptions();

      //Set default to After, if roof inspection
      if( props.location.state.flag_RoofInspection ) {
        setValues({id:'',beforeAfter:'After'});
      }
    }, [getTokenSilently]);

    const onTakePhoto = (dataUri) => {
        let currentImages = images;
        currentImages.unshift({data: dataUri, description: ''});
        setImages(currentImages);
        let number = showImages;
        setShowImages(number + 1);
        console.log(images.length)
        // Do stuff with the dataUri photo...
      };
      function handleTextFieldChange(textValue)
      {
        setCaption(textValue.target.value);
      }
      function handleClose(type) {
        setValues({id:'',beforeAfter:'Before'});
        setOpen(false);
        
      }
      function handleChange(event) {
        setValues(oldValues => ({
          ...oldValues,
          [event.target.name]: event.target.value,
        }));
      }
      function addImage(__ID, beforeAfter)
      {
        if(beforeAfter)
        {
          setValues({__ID:__ID,beforeAfter:'Before'});
          setOpen(true);
          console.log(values);
        }
        else
        {
          setValues({__ID:__ID,beforeAfter:'After'});
          setOpen(true);
        }
        
      }
      const removeImage = (i) => {
        let currentImages = images;
        currentImages.splice(i, 1);
        setImages(currentImages);
        let number = showImages;
        setShowImages(number - 1);
        
      };
      const uploadImages = () => {
        console.log(images);
        setUploading(true);
        images.forEach((image, i) => {
          var formData = new FormData();
          formData.append('upload', images[i].data);
          formData.append('_idf_job', props.location.state.__ID);
          console.log(props.location.state.currentFeature)
          if(props.location.state.currentFeature != null)
          {
            formData.append('markerId', props.location.state.currentFeature);
          }
          else
          {
            formData.append('markerId', "");
          }
          formData.append('imageDescription', caption);
          formData.append('beforeAfter', values.beforeAfter);
          formData.append('__ID', values.id);
          uploadImage(formData, 0);
        })
      };

      const uploadImage = async (form, i) => {
        try {
          const token = await getTokenSilently();
      
          const response = await fetch(appPaths.apiPath + "/api/uploadImages", {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Accept': 'application/json'   // expected data sent back
            },
            body: form
          });
      
          const responseData = await response.json();
          if(responseData.data.message || i > 9)
          {
            if(i > 9)
            {
              if(window.confirm("Image failed to upload! Would you like to try resending it?"))
              {
                uploadImage(form, 0);
              }
            }
            else
            {
              setNewImages(newImages + 1);
              setImages([]);
              setCaption('');
              handleClose();
              let data = {set: true, to: {pathname: "/job-map", state: {
                __ID: props.location.state.__ID,
                flag_RoofInspection: props.location.state.flag_RoofInspection
              }}};
              setRedirectDetails(data);
            }
            setUploading(false);
            
          }
          else
          {
            uploadImage(form, i + 1)
          }
        
        } catch (error) {
          console.error(error);
          setUploading(false);
        }
      };

    const handleCaptionChange = event => {
      setCaption(event.target.value)
    }

    return (
        <div className={classes.jobCameraContainer}>

            {redirectDetails.set && 
            (<Redirect push to={redirectDetails.to} />
          )}

          {uploading && <><CircularProgress className={classes.jobFullWidth} /></>}
 
          {!uploading &&
          <>
 
           <Paper className={classes.jobCameraMargin}>

            {images.length == 0 && <div className={classes.jobPosition}>

            <Camera
                imageType="jpg"
                idealFacingMode = {idealFacingMode}
                isImageMirror={mirror}
                onTakePhoto = { (dataUri) => { onTakePhoto(dataUri); } }
            />
            
            <Button onClick={(event) => {if(idealFacingMode == 'environment') {setIdealFacingMode('user'); setMirror(true);} else { setIdealFacingMode('environment'); setMirror(false); }}} variant="contained" className={classes.jobCameraIcon}><ReplayIcon /></Button></div>}
            
            <div className={classes.jobCameraContainer}>
            
            {images.length > 0 && images.map((row, i) => (

                <div key={i} className={classes.jobPosition}>
                  <img className={classes.jobFullWidth} src={row.data}></img>
                  <Button onClick={(event) => {removeImage(i); setCaption('')}} variant="contained" className={classes.jobCameraIcon}>X</Button></div>
            ))}
            
            {images.length > 0 &&

              <div className={classes.jobFullWidth}>
                <div className={classes.jobSpacing}>
                  <Select
                    onChange={handleChange}
                    value={values.beforeAfter}
                    className={classes.jobFullWidth}
                    input={<OutlinedInput name="beforeAfter" id="beforeAfter" />}
                  >
                    <MenuItem value='Before'>Before</MenuItem>
                    <MenuItem value='After'>After</MenuItem>
                    <MenuItem value='Other'>Other</MenuItem>
                  </Select>
                </div>

                <div className={classes.jobSpacing}>
                  {values.beforeAfter === 'Before' &&
                    <Select
                      className={classes.jobFullWidth}
                      onChange={handleCaptionChange}
                      value={caption}
                    >
                      {captionList.filter(caption => caption.fieldData.flag_before === '1')
                      .map(material => <MenuItem key={material.fieldData.__ID} value={material.fieldData.caption}>{material.fieldData.caption}</MenuItem>)}
                    </Select>
                  }
                  {values.beforeAfter === 'After' &&
                    <Select
                      className={classes.jobFullWidth}
                      onChange={handleCaptionChange}
                      value={caption}
                    >
                      {captionList.filter(caption => caption.fieldData.flag_before === '0')
                      .map(material => <MenuItem key={material.fieldData.__ID} value={material.fieldData.caption}>{material.fieldData.caption}</MenuItem>)}
                    </Select>
                  }
                  {values.beforeAfter === 'Other' &&
                    <TextField
                      className={classes.jobFullWidth}
                      onChange={handleCaptionChange}
                      value={caption}
                    />
                  }
                </div>

                {/* <InvoiceMaterialUnitsDropdown
                  changeValue={setCaption}
                  value={caption}
                  changeChoice={() => {}}
                  materials={values.beforeAfter  == "Before" ? [...new Set(captionList.filter(caption => caption.fieldData.flag_before == "1").map(material => { return material.fieldData.caption }))] : [...new Set(captionList.filter(caption => caption.fieldData.flag_before != "1").map(material => { return material.fieldData.caption }))]}
                  className={classes.jobFullWidth}
                  label="Caption"
                  placeholder="Caption"
                /> */}
                
                <Button variant="contained" className={classes.jobCameraMargin} color="primary" onClick={() => {uploadImages()}}>Upload Image</Button>
              </div>
            }
            </div>

          </Paper>
          </>
          }

      </div>
    );
}