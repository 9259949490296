import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'
import { useAuth0 } from '../../react-auth0-wrapper'
import appPaths from '../../applicationPaths.json'
import BottomNavBar from '../NavBars/bottomNavbar'

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const {
    isAuthenticated,
    loginWithRedirect,
    user,
    getTokenSilently,
    loading
  } = useAuth0()
  const [permissionType, setPermissionType] = useState([])

  useEffect(() => {
    const fn = async () => {
      if (!isAuthenticated) {
        await loginWithRedirect({
          appState: { targetUrl: path }
        })
      }
    }
    fn()
  }, [isAuthenticated, loginWithRedirect, path])

  useEffect(() => {
    const getPermissions = async () => {
      try {
        const token = await getTokenSilently()
        const response = await fetch(
          appPaths.apiPath + '/api/getUserPermissions',
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )

        const responseData = await response.json()
        setPermissionType(responseData)
      } catch (error) {
        console.error(error)
      }
    }
    getPermissions()
  }, [getTokenSilently, user])
  const render = props =>
    loading ? (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          textAlign: 'center'
        }}
      >
        Loading
      </div>
    ) : isAuthenticated === true && permissionType.includes('type:admin') ? (
      <Component {...props} />
    ) : (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          textAlign: 'center'
        }}
      >
        Code 403: You must be an admin to view this page
      </div>
    )

  return (
    <>
      <Route path={path} render={render} {...rest} />
      <BottomNavBar />
    </>
  )
}

export default PrivateRoute
