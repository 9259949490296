import AuthConfig from '../../../auth_config.json'
import appPaths from '../../../applicationPaths.json'
import Opportunity from './Opportunity'
import whiteCircle from '../../../assets/images/white_circle_small.png'

import * as geolib from 'geolib'

//Generates a static map URL of Google Map of all markers and shapes
const generateSnapshot = (map, markers, snapshotSize) => {
  let center = map.getCenter()
  let zoom = map.getZoom()
  let size = snapshotSize.width + 'x' + snapshotSize.height
  let maptype = 'satellite'
  let markerQuery = ''
  let path = ''
  let key = AuthConfig.staticMapKey
  // let whiteCircle = encodeURIComponent(
  //   'https://highpowerdata.com/wp-content/uploads/2019/12/white_circle_small.png'
  // )

  //Add the roof sections to the query string
  map.data.forEach(feature => {
    if (feature.getGeometry().getType() === 'Polygon') {
      path += '&path='
      path +=
        'color:0x00000000' +
        '|fillcolor:0x' +
        feature.getProperty('color').substring(1) +
        '|weight:5'
      feature.getGeometry().forEachLatLng(latlng => {
        path += '|' + latlng.lat() + ',' + latlng.lng()
      })
    }

    if (feature.getProperty('isMarker') !== undefined) {
      feature.getGeometry().forEachLatLng(latlng => {
        markerQuery += '&markers='
        markerQuery += 'label:' + feature.getProperty('label')
        markerQuery += '|' + latlng.lat() + ',' + latlng.lng()
      })
    }
  })

  //Add the number markers
  // markers.forEach((marker, i) => {
  //     markerQuery += '&markers=';
  //     markerQuery += "icon:"+ appPaths.apiPath + "/m/" + (i+1) + '.png';
  //     markerQuery += "|" + marker.lat + "," + marker.lng;
  // });

  markers.forEach((marker, i) => {
    if (i + 1 > 9) {
      markerQuery += '&markers='
      markerQuery += 'icon:' + appPaths.apiPath + '/m/' + (i + 1) + '.png'
      markerQuery += '|' + marker.lat + ',' + marker.lng
    } else {
      markerQuery += '&markers='
      markerQuery += 'icon:' + appPaths.apiPath + '/m/' + (i + 1) + '.png'
      markerQuery += '|' + marker.lat + ',' + marker.lng
    }
  })

  let url = 'https://maps.googleapis.com/maps/api/staticmap?'
  url += 'center=' + center.lat() + ',' + center.lng()
  url += '&zoom=' + zoom
  url += '&size=' + size
  url += '&maptype=' + maptype
  url += markerQuery
  url += path
  url += '&key=' + key

  return url
}

//Generates an array with objects of roof section names, areas, perimeters, and static map URLs for each of the sections
const generateSnapshotList = async (
  map,
  markers,
  snapshotSize,
  opportunity
) => {
  let list = []
  let base_url = 'https://maps.googleapis.com/maps/api/staticmap?'

  let center = map.getCenter()
  let zoom = map.getZoom()
  let size = snapshotSize.width + 'x' + snapshotSize.height
  let maptype = 'satellite'
  let key = AuthConfig.staticMapKey
  let i = 0
  var mark = []

  map.data.forEach(features => {
    features.getGeometry().forEachLatLng(latlng => {
      if (features.getProperty('isMarker')) {
        mark.push({
          label: features.getProperty('label'),
          lat: latlng.lat(),
          lng: latlng.lng()
        })
      }
    })
  })

  //Add the roof sections to the query string
  map.data.forEach(async feature => {
    let item = {}
    item.url = base_url
    let path = ''
    let markerQuery = ''

    if (feature.getProperty('isMarker') === undefined) {
      //add the roof section
      var markerIcon = await checkMarkerPostion(
        opportunity,
        mark,
        feature.getProperty('id')
      )

      console.log(markerIcon, 'markerIcon')
      if (feature.getGeometry().getType() === 'Polygon') {
        path += '&path='
        path +=
          'color:0x00000000' +
          '|fillcolor:0x' +
          feature.getProperty('color').substring(1) +
          '|weight:5'
        feature.getGeometry().forEachLatLng(latlng => {
          path += '|' + latlng.lat() + ',' + latlng.lng()
        })
      }
      //add the marker
      if (i + 1 > 9) {
        markerQuery += '&markers='
        markerQuery += 'icon:' + appPaths.apiPath + '/m/' + (i + 1) + '.png'
        markerQuery += '|' + markers[i].lat + ',' + markers[i].lng
      } else {
        markerQuery += '&markers='
        markerQuery += 'icon:' + appPaths.apiPath + '/m/' + (i + 1) + '.png'
        console.log(markers[i], 'markerQuery')
        if (markers[i] !== undefined) {
          markerQuery += '|' + markers[i].lat + ',' + markers[i].lng
        }
      }

      markerIcon.forEach((m, i) => {
        markerQuery += '&markers='
        markerQuery += 'label:' + m.label
        markerQuery += '|' + m.lat + ',' + m.lng
      })

      // set cordinate to make relation with marker and roof
      if (markers[i] !== undefined) {
        item.lat = markers[i].lat
        item.lng = markers[i].lng
      }

      //set the area and perimeter
      item.area = feature.getProperty('area')
      item.id = feature.getProperty('id')
      item.perimeter = feature.getProperty('perimeter')
      //set the rooftype and
      item.roof_type_id = feature.getProperty('roof_type')
      item.remaining_year = feature.getProperty('remaining_year')
      item.replacement_estimate = feature.getProperty('replacement_estimate')
      item.repair_estimate = feature.getProperty('repair_estimate')
      item.cost_for_section = feature.getProperty('cost_for_section')
      //set the name
      item.name = 'Roof Section ' + (i + 1)

      i++
    } else {
      feature.getGeometry().forEachLatLng(latlng => {
        markerQuery += '&markers='
        markerQuery += 'label:' + feature.getProperty('label')
        markerQuery += '|' + latlng.lat() + ',' + latlng.lng()
        item.lat = latlng.lat()
        item.lng = latlng.lng()
      })

      item.name = 'Marker ' + feature.getProperty('label')
      item.isMarker = true
    }

    //set the url query for this item in the list
    item.url += 'center=' + center.lat() + ',' + center.lng()
    item.url += '&zoom=' + zoom
    item.url += '&size=' + size
    item.url += '&maptype=' + maptype
    item.url += markerQuery
    item.url += path
    item.url += '&key=' + key

    //set the description
    item.description = feature.getProperty('description')

    //add this item to the list
    list.push(item)
  })

  return list
}

const generateMarkerPhotos = (map, photos) => {
  let list = []
  let i = 0
  let markerQuery = ''
  map.data.forEach(feature => {
    let markerId = feature.getProperty('id')

    //get the photos associated with this marker
    photos
      .filter(function (photo) {
        return photo._idf_marker === markerId
      })
      .forEach(function (photo) {
        let item = {}
        item.url = 'https://maps.googleapis.com/maps/api/staticmap?'
        item.type = photo.BeforeAfter
        feature.getGeometry().forEachLatLng(latlng => {
          markerQuery += '&markers='
          markerQuery += 'label:' + feature.getProperty('label')
          markerQuery += '|' + latlng.lat() + ',' + latlng.lng()
          item.lat = latlng.lat()
          item.lng = latlng.lng()
        })
        if (feature.getProperty('isMarker') === undefined) {
          item.name = 'Roof Section ' + (i + 1)
        } else {
          item.name = 'Marker ' + feature.getProperty('label')
        }
        item.name += ' - Photo'

        item.url += '&size=' + '40x40'

        item.url += markerQuery
        item.url += '&key=' + AuthConfig.staticMapKey

        item.description = photo.Description
        item.imgData = photo.base64Img
        list.push(item)
      })

    if (feature.getProperty('isMarker') === undefined) {
      i++
    }
  })

  return list
}

//Generates a PDF from a single snapshot and a list of roof section objects
// HPDS Key Line
const generatePDF = async (opportunity, snapshot, list) => {
  //generate a pdf document
  var pdf = new window.jsPDF('p', 'pt', 'letter')
  pdf.canvas.height = 72 * 11 //792
  pdf.canvas.width = 72 * 8.5 //612
  let x = 25
  let y = 25
  let imgWidth = 180 //281;
  let imgHeight = 180 //281;
  let headerImgHeight = 281
  let headerImgWidth = 281

  pdf.setFontSize(11)

  //Header
  // let logo = await getImageData(
  //   'https://highpowerdata.com/wp-content/uploads/2019/12/white_circle_small.png'
  // )
  pdf.addImage(whiteCircle, 'PNG', x, y, 250, 45)
  let companyInfo = [
    '8906 Autumn Oaks Drive',
    'Rockford, MN 55373',
    '',
    'Phone: (763) 316-0660',
    'Email: acct.duraroof@gmail.com',
    'Web: www.duraroofusa.com'
  ]
  let textOptions = {
    align: 'right'
  }
  pdf.text(companyInfo, 587, y, textOptions, 0)
  y += 45 + 32

  //line break
  pdf.line(x, y, 612 - 25, y)

  pdf.setFontSize(20)
  y += 32
  pdf.text(x, y, opportunity.reportTitle)

  pdf.setFontSize(12)

  //Date on the right
  let dateStr = 'Date: ' + new Date(Date.now()).toLocaleString().split(',')[0]
  pdf.text(dateStr, 587, y - 8, textOptions, 0)

  y += 24

  //Opportunity Info
  let opportunityInfo = [opportunity.name, opportunity.address]
  // pdf.text(opportunityInfo, x, y, {}, 0);
  // y += 48;

  //get snapshot image data and add to pdf
  let imgData = await getImageData(snapshot)

  pdf.addImage(
    imgData,
    'PNG',
    x + 125,
    y + 100,
    headerImgWidth,
    headerImgHeight
  )
  pdf.text(opportunityInfo, x + 125, y + 400, {}, 0)
  y += 48
  //pdf.addPage('letter', 'p');
  y += headerImgHeight + 50

  //get each of the roof sections image data and add to pdf
  let img = []
  let roofSection = list.filter(val => val.url && !val.name.includes('Marker'))

  const markers = list.filter(f => f.isMarker === true)
  console.log('ROOFINS', roofSection)
  let estArray = []
  let estimateTotal
  for (let i = 0; i < roofSection.length; i++) {
    if (roofSection[i].repair_estimate !== undefined) {
      let item = roofSection[i].repair_estimate
        .replace('$', '')
        .replace(',', '')
      estArray.push(parseFloat(item))
      estimateTotal = estArray.reduce((x, y) => x + y)
      estimateTotal = estimateTotal.toFixed(2)
    }
  }

  for (let i = 0; i < roofSection.length; i++) {
    let section = opportunity.map_data.features.filter(
      f => f.properties.id === roofSection[i].id
    )

    let marker_photo_in_section = []
    if (section !== undefined && section.length > 0) {
      let coordinates = section[0].geometry.coordinates

      for (const marker of markers) {
        const cord_list = []
        for (const cord of coordinates[0]) {
          cord_list.push({ latitude: cord[1], longitude: cord[0] })
        }

        var is_in_polygon = await geolib.isPointInPolygon(
          { latitude: marker.lat, longitude: marker.lng },
          cord_list
        )

        if (is_in_polygon) {
          let marker_photo = list.filter(
            l => l.name === marker.name + ' - Photo'
          )
          if (marker_photo !== undefined && marker_photo.length > 0) {
            marker_photo.forEach(mp => {
              mp.markerDescription = marker.description
            })
            marker_photo_in_section.push(...marker_photo)
          }
        }
      }
    }
    console.log(
      'marker in' + roofSection[i].name + ' ========1==========',
      marker_photo_in_section
    )

    let desLen

    if (roofSection[i].url && !roofSection[i].name.includes('Marker')) {
      pdf.addPage('letter', 'p')
      x = 25
      y = 50
    }
    // Making the gap between description and cost for repair
    if (roofSection[i].description) {
      desLen = roofSection[i].description.length

      desLen = Math.round(desLen / 72)
    }

    if (roofSection[i].imgData !== undefined) {
      imgData = roofSection[i].imgData
    } else {
      imgData = await getImageData(roofSection[i].url)
    }

    //rotate the image
    var rotation = 0
    var imgProperties = pdf.getImageProperties(imgData)
    var orientation = await getOrientation(imgData)

    if (orientation === 6) {
      rotation = -90

      y -= imgProperties.height
      x += 59
    } else if (orientation === 3) {
      rotation = -180
      x += imgProperties.width
      y -= imgProperties.height
    }

    //adjust image width proportionally
    var width = (imgProperties.width / imgProperties.height) * imgHeight
    var height = imgHeight

    //adjust height proportionally
    if (width > imgWidth) {
      width = imgWidth
      height = (imgProperties.height / imgProperties.width) * width
    }

    let before = []
    let after = []
    before = marker_photo_in_section.filter(val => val.type == 'Before')
    after = marker_photo_in_section.filter(val => val.type == 'After')
    let beforeLen = before.length
    let afterLen = after.length
    let max = beforeLen > afterLen ? beforeLen : afterLen

    let roof_type = roofSection[i].roof_type_id
      ? roofSection[i].roof_type_id
      : 'N/A'
    let remain_yr = roofSection[i].remaining_year
      ? roofSection[i].remaining_year
      : 'N/A'
    let replacement_est = roofSection[i].replacement_estimate
      ? roofSection[i].replacement_estimate.includes('$', 0)
        ? roofSection[i].replacement_estimate
        : '$' + roofSection[i].replacement_estimate
      : 'N/A'
    let repair_eti = roofSection[i].repair_estimate
      ? roofSection[i].repair_estimate.includes('$', 0)
        ? roofSection[i].repair_estimate
        : '$' + roofSection[i].repair_estimate
      : 'N/A'
    let cost_for_section = roofSection[i].cost_for_section
      ? roofSection[i].cost_for_section.includes('$', 0)
        ? roofSection[i].cost_for_section
        : '$' + roofSection[i].cost_for_section
      : 'N/A'
    let roofDescription = roofSection[i].description
      ? roofSection[i].description
      : 'N/A'

    if (
      roofSection[i].area !== undefined &&
      roofSection[i].perimeter !== undefined
    ) {
      pdf.addImage(imgData, 'PNG', x, y, width, height)

      //Add text
      pdf.setFontSize(16)
      pdf.text(roofSection[i].name, x + imgWidth + 6, y, { baseline: 'top' })

      pdf.setFontSize(11)

      pdf.text(
        'Area: ' + roofSection[i].area.toFixed(2) + ' Square Feet',
        210,
        y + 32,
        0
      )
      pdf.text(
        'Perimeter: ' + roofSection[i].perimeter.toFixed(2) + ' Lineal Feet',
        410,
        y + 32,
        0
      )
      pdf.text('Type of Roof: ' + roof_type, 210, y + 48, {}, 0)
      pdf.text('Est Year of Replacement: ' + remain_yr, 410, y + 48, {}, 0)
      pdf.text('Est Replacement Cost: ' + replacement_est, 210, y + 64, {}, 0)
      pdf.text('Yearly Repair Budget: ' + repair_eti, 410, y + 64, {}, 0)
      pdf.text(
        x + imgWidth + 5,
        y + 78,
        'Cost of Repair for this Section: ' + cost_for_section
      )
      pdf.text(x + imgWidth + 5, y + 95, 'Condition of Roof/Comment:')
      pdf.text(x + imgWidth + 5, y + 110, splitString(roofDescription, 72))
    }

    for (let k = 0; k < max; k++) {
      if (y > 350) {
        pdf.addPage('letter', 'p')
        x = 25
        y = 50
        if (before[k]) {
          pdf.addImage(before[k].imgData || '', 'PNG', x, y, width + 32, 125)
          pdf.setFontSize(12)

          pdf.text(
            before[k].name.split(' ')[1] + ' - ' + before[k].markerDescription,
            x,
            y - 30,
            { baseline: 'top' }
          )
          pdf.setFontSize(11)

          pdf.text(x, y + 145, 'Description:')
          pdf.text(x, y + 160, splitString(before[k].description, 72))
        }

        // After images
        if (after[k]) {
          pdf.addImage(after[k].imgData || '', 'PNG', 350, y, width + 32, 125)
          pdf.text(350, y + 145, 'Description:')
          pdf.text(350, y + 160, splitString(after[k].description, 72))
        }
        y += imgWidth - 80
      } else {
        if (before[k]) {
          // let markerIcon = await getImageData(
          //   'https://highpowerdata.com/wp-content/uploads/2019/12/white_circle_small.png'
          // )

          pdf.addImage(
            before[k].imgData || '',
            'PNG',
            x,
            y + imgHeight + 85,
            width + 32,
            125
          )
          pdf.setFontSize(12)

          pdf.addImage(whiteCircle || '', 'PNG', x, y + imgHeight + 40, 25, 25)
          pdf.text(
            'Marker ' +
              before[k].name.split(' ')[1] +
              ' - ' +
              before[k].markerDescription,
            x + 30,
            y + imgHeight + 55,
            { baseline: 'top' }
          )

          pdf.setFontSize(11)

          pdf.text(x, y + imgHeight + imgWidth + 40, 'Description:')
          pdf.text(
            x,
            y + imgHeight + imgWidth + 55,
            splitString(before[k].description, 72)
          )
        }

        // After images
        if (after[k]) {
          pdf.addImage(
            after[k].imgData || '',
            'PNG',
            350,
            y + imgHeight + 85,
            width + 32,
            125
          )
          pdf.text(350, y + imgHeight + imgHeight + 40, 'Description:')
          pdf.text(
            350,
            y + imgHeight + imgHeight + 55,
            splitString(after[k].description, 72)
          )
        }
        y += imgWidth + 20
      }
    }

    y += imgHeight + 50
  }

  pdf.setFontSize(16)

  if (y > 450) {
    pdf.addPage('letter', 'p')
    x = 25
    y = 20
    let yearly_est_total = 0
    pdf.text('ROOF INSPECTION RECAP', 180, y, { baseline: 'top' })
    pdf.setFontSize(11)

    for (let m = 0; m < roofSection.length; m++) {
      if (!roofSection[m].name.includes('Marker')) {
        let remain_yr = roofSection[m].remaining_year
          ? roofSection[m].remaining_year
          : ''
        let replacement_est = roofSection[m].replacement_estimate
          ? roofSection[m].replacement_estimate
          : ''
        let repair_eti = roofSection[m].repair_estimate
          ? roofSection[m].repair_estimate
          : ''
        pdf.setFontSize(16)
        pdf.text(roofSection[m].name, x, y + 35, { baseline: 'top' })
        pdf.setFontSize(11)
        pdf.text(x, y + 66, 'Est Year of Replacement:')
        pdf.text(x + 128, y + 66, remain_yr.toString())
        pdf.text(x + 180, y + 66, 'Est Replacement Cost:')
        pdf.text(x + 296, y + 66, replacement_est)
        pdf.text(x + 385, y + 66, 'Yearly Repair Budget: $150')
        pdf.text(x, y + 88, 'Repair Cost:')
        pdf.text(x + 63, y + 88, repair_eti)
        pdf.text(x + 180, y + 88, 'Accepted On: ________________')
        pdf.text(x + 385, y + 88, 'Date: __________________')
        y += 60
      }
      yearly_est_total += roofSection[m].repair_estimate
    }
    // pdf.text(yearly_est_total.toString(), 180, y, { baseline: 'top' })
    console.log(yearly_est_total, 'est total')
  } else {
    let yearly_est_total = 0

    pdf.text('ROOF INSPECTION RECAP', 180, y, { baseline: 'top' })
    pdf.setFontSize(11)

    for (let m = 0; m < roofSection.length; m++) {
      if (!roofSection[m].name.includes('Marker')) {
        let remain_yr = roofSection[m].remaining_year
          ? roofSection[m].remaining_year
          : ''
        let replacement_est = roofSection[m].replacement_estimate
          ? roofSection[m].replacement_estimate
          : ''
        let repair_eti = roofSection[m].repair_estimate
          ? roofSection[m].repair_estimate
          : ''
        pdf.setFontSize(16)
        pdf.text(roofSection[m].name, x, y + 35, { baseline: 'top' })
        pdf.setFontSize(11)
        pdf.text(x, y + 66, 'Est Year of Replacement:')
        pdf.text(x + 128, y + 66, remain_yr.toString())
        pdf.text(x + 180, y + 66, 'Est Replacement Cost:')
        pdf.text(x + 296, y + 66, replacement_est)
        pdf.text(x + 385, y + 66, 'Yearly Repair Budget: $150')
        pdf.text(x, y + 88, 'Repair Cost:')
        pdf.text(x + 63, y + 88, repair_eti)
        pdf.text(x + 180, y + 88, 'Accepted On: ________________')
        pdf.text(x + 385, y + 88, 'Date: __________________')
        y += 60
      }
      yearly_est_total += roofSection[m].repair_estimate
    }
    console.log(yearly_est_total, 'est total before replace')
    // yearly_est_total = yearly_est_total.replace(/[^0-9\.]+/g, '')
    // pdf.text(yearly_est_total.toString(), 180, y, { baseline: 'bottom' })
    console.log(yearly_est_total, 'est total')
  }

  //Download the PDF in the browser
  // console.log(opportunity.customer);
  //Rennel Parino HPDS 9/29/2022 - Changed filename output
  pdf.save(opportunity.reportTitle + '_' + opportunity.customer + '.pdf')

  //Return a base 64 encoded PDF for saving to server
  return btoa(pdf.output())
}

const getImageData = async url => {
  //Fetch base 64 image data
  let response = await fetch(url)
  let buffer = await response.arrayBuffer()
  let imageData = 'data:image/png;base64,' + arrayBufferToBase64(buffer)

  return imageData
}

//Taken from https://medium.com/front-end-weekly/fetching-images-with-the-fetch-api-fb8761ed27b2
const arrayBufferToBase64 = buffer => {
  var binary = ''
  var bytes = [].slice.call(new Uint8Array(buffer))

  bytes.forEach(b => (binary += String.fromCharCode(b)))

  return window.btoa(binary)
}

//https://stackoverflow.com/questions/7584794/accessing-jpeg-exif-rotation-data-in-javascript-on-the-client-side
function getOrientation (base64img) {
  return new Promise((resolve, reject) => {
    var base64string = base64img.split(',')[1]
    var arrayBuffer = base64ToArrayBuffer(base64string)

    var view = new DataView(arrayBuffer)
    if (view.getUint16(0, false) != 0xffd8) {
      return resolve(-2)
    }
    var length = view.byteLength,
      offset = 2
    while (offset < length) {
      if (view.getUint16(offset + 2, false) <= 8) return resolve(-1)
      var marker = view.getUint16(offset, false)
      offset += 2
      if (marker == 0xffe1) {
        if (view.getUint32((offset += 2), false) != 0x45786966) {
          return resolve(-1)
        }

        var little = view.getUint16((offset += 6), false) == 0x4949
        offset += view.getUint32(offset + 4, little)
        var tags = view.getUint16(offset, little)
        offset += 2
        for (var i = 0; i < tags; i++) {
          if (view.getUint16(offset + i * 12, little) == 0x0112) {
            return resolve(view.getUint16(offset + i * 12 + 8, little))
          }
        }
      } else if ((marker & 0xff00) != 0xff00) {
        break
      } else {
        offset += view.getUint16(offset, false)
      }
    }
    return resolve(-1)
  })
}

function base64ToArrayBuffer (base64) {
  var binary_string = window.atob(base64)
  var len = binary_string.length
  var bytes = new Uint8Array(len)
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i)
  }
  return bytes.buffer
}

const splitString = (str, charNum) => {
  if (typeof str === 'undefined') {
    return []
  }

  let arr = []
  let words = str.split(' ')
  let currentWord = ''
  let nextWord = ''
  let line = ''

  for (var i = 0; i < words.length; i++) {
    currentWord = words[i]
    if (i < words.length - 1) {
      nextWord = words[i + 1]
    } else {
      nextWord = null
    }

    line += currentWord + ' '

    if (nextWord !== null && line.length + nextWord.length > charNum) {
      arr.push(line)
      line = ''
    }
  }

  //push the last line
  arr.push(line)

  return arr
}

const checkMarkerPostion = async (opportunity, markers, id) => {
  let section = opportunity.map_data.features.filter(
    f => f.properties.id === id
  )

  let marker_photo_in_section = []
  if (section !== undefined && section.length > 0) {
    let coordinates = section[0].geometry.coordinates

    markers.forEach(async marker => {
      const cord_list = []
      if (coordinates.length === 1) {
        for (const cord of coordinates[0]) {
          cord_list.push({ latitude: cord[1], longitude: cord[0] })
        }
      }

      var is_in_polygon = await geolib.isPointInPolygon(
        { latitude: marker.lat, longitude: marker.lng },
        cord_list
      )

      if (is_in_polygon) {
        marker_photo_in_section.push(marker)
      }
    })

    return marker_photo_in_section
  }
}

export {
  generateSnapshot,
  generateSnapshotList,
  generatePDF,
  generateMarkerPhotos
}
