import React, { useState, useEffect, useContext } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import MobileStepper from '@material-ui/core/MobileStepper'
import Button from '@material-ui/core/Button'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import InvoiceMarkers from './InvoiceMarkers'
import InvoiceImages from './InvoiceImages'
import InvoiceOverview from './InvoiceOverview'
import InvoiceMaterials from './InvoiceMaterials'
import InvoiceAccounting from './InvoiceAccounting'
import {
  Stepper,
  Step,
  StepButton,
  Typography,
  Paper,
  CircularProgress
} from '@material-ui/core'
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder
} from 'react-beautiful-dnd';
import { useAuth0 } from '../../react-auth0-wrapper'
import appPaths from '../../applicationPaths.json'
import { Redirect } from 'react-router'
import InvoicePreview from './InvoicePreview'
import { RoofContext } from "../../AppContext";

const useStyles = makeStyles(theme => ({
  stepper: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
}))

function getSteps () {
  return ['Markers', 'Images', 'Materials', 'Create Invoice', 'Review']
}

export default function Invoice (props) {
  const classes = useStyles()
  const theme = useTheme()
  const [activeStep, setActiveStep] = useState(0)
  const steps = getSteps()
  const [selectedImages, setSelectedImages] = useState([])
  const [images, setImages] = useState([])
  const [imageMapData, setImageMapData] = useState([])
  const [mapRef, setMapRef] = useState(null)
  const [center, setCenter] = useState(null)
  const [zoom, setZoom] = useState(21)
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    getTokenSilently,
    user
  } = useAuth0()
  const [invoiceLoading, setInvoiceLoading] = useState(false)

  const { roof, setRoof } = useContext(RoofContext);
  const setMaterialList = (materialList) => {
    setRoof({ materialList })
  }
  /* const [materialList, setMaterialList] = useState([
    { name: '', price: '', qty: '', units: '' },
    { name: '', price: '', qty: '', units: '' },
    { name: '', price: '', qty: '', units: '' }
  ]) */
  const [trips, setTrips] = useState([
    { name: 'Truck Trips', price: 100, qty: '0' }
  ])
  const [miscItems, setMiscItems] = useState([
    { name: 'Admin Fee', price: '24.50', qty: '1' },
    { name: 'Shop Supply', price: '0.00', qty: '1' }
  ])
  const [redirectDetails, setRedirectDetails] = useState({
    set: false,
    location: '/job'
  })
  const [features, setFeatures] = useState([])
  const [permissionType, setPermissionType] = useState([])
  const [laborData, setLaborData] = useState([])
  const [jobDetailsLoaded, setJobDetailsLoaded] = useState(false)
  const [salesperson, setSalesperson] = useState('')
  const [taxChecked, setTaxChecked] = useState(false)
  const [goBack, setGoBack] = useState(false)
  const [jobDetails, setJobDetails] = useState(null)
  const [previewUrl, setPreviewUrl] = useState('')
  const [showInvoicePreview, setShowInvoicePreview] = useState(false)
  const [invoiceId, setInvoiceId] = useState('')
  const [invoiceSaving, setInvoiceSaving] = useState(false)
  const [timecards, setTimecards] = useState([])
  const [timecardsLoaded, setTimecardsLoaded] = useState(false)
  const [oldMaterials, setOldMaterials] = useState([]);

  const getOldMaterials = async () => {
    try {
      const token = await getTokenSilently()
      const response = await fetch(
        appPaths.apiPath + '/api/getJobMaterials',
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json', // sent request
            Accept: 'application/json' // expected data sent back
          },
          body: JSON.stringify({ jobId: props.location.state.__ID })
        }
      )
      const responseData = await response.json()
      const materials = responseData.data.response.data.map(record => {
        const material = {
          recordId: record.recordId,
          name: record.fieldData.Name,
          price: record.fieldData.Price,
          qty: record.fieldData.Qty,
          units: record.fieldData.Units
        }

        return material
      })

      setOldMaterials(materials)
      setMaterialList(materials)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const getPermissions = async () => {
      try {
        const token = await getTokenSilently()
        const response = await fetch(
          appPaths.apiPath + '/api/getUserPermissions',
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )

        const responseData = await response.json()

        setPermissionType(responseData)
      } catch (error) {
        console.error(error)
      }
    }

    getPermissions()
    getOldMaterials()
  }, [getTokenSilently, user])

  useEffect(() => {
    if (!jobDetailsLoaded) {
      const callApi = async () => {
        try {
          const token = await getTokenSilently()
          const response = await fetch(
            appPaths.apiPath + '/api/getJobDetails',
            {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json', // sent request
                Accept: 'application/json' // expected data sent back
              },
              body: JSON.stringify({ id: props.location.state.__ID })
            }
          )

          const responseData = await response.json()
          if (responseData.message) {
            setJobDetailsLoaded(true)
            setSalesperson(
              responseData.data.response.data[0].fieldData.Salesperson
            )
            setJobDetails(responseData.data.response.data[0].fieldData)
          } else {
            //alert(responseData);
          }
        } catch (error) {
          console.error(error)
          setJobDetailsLoaded(false)
        }
      }

      callApi()
    }
  }, [getTokenSilently, props.location.state.__ID, jobDetailsLoaded])

  useEffect(() => {
    if (!timecardsLoaded) {
      const callApi = async () => {
        try {
          const token = await getTokenSilently()
          const response = await fetch(appPaths.apiPath + '/api/getTimecards', {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json', // sent request
              Accept: 'application/json' // expected data sent back
            },
            body: JSON.stringify({ id: props.location.state.__ID })
          })

          const responseData = await response.json()

          if (responseData.message) {
            setTimecardsLoaded(true)
            setTimecards(responseData.data.response.data)
          } else {
          }
        } catch (error) {
          console.error(error)
          setTimecardsLoaded(false)
        }
      }

      callApi()
    }
  }, [getTokenSilently, props.location.state.__ID, timecardsLoaded])

  function handleNext () {
    /*if(activeStep == 0)
      {
        html2canvas(document.getElementById('googleMarkerMap'), {
          useCORS: true,
      allowTaint: false,
      ignoreElements: (node) => {
        return node.nodeName === 'IFRAME';
      }
        }).then(function(canvas) {
          //console.log(canvas);
          document.getElementById('mapImage').appendChild(canvas);
      });
      }
*/
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  function handleBack () {
    if (activeStep === 0) {
      let data = {
        set: true,
        to: { pathname: '/job', state: { __ID: props.location.state.__ID } }
      }
      setRedirectDetails(data)
      setGoBack(true)
    } else {
      setActiveStep(prevActiveStep => prevActiveStep - 1)
    }
  }

  function addImages (index) {
    setSelectedImages(prevState => {
      return [
        ...images.filter(image => image.fieldData._idf_marker == index),
        ...prevState
      ]
    })
  }
  function removeImages (index) {
    setSelectedImages(prevState => {
      return [
        ...prevState.filter(image => image.fieldData._idf_marker != index)
      ]
    })
  }

  const createInvoice = (mapUrl, description, type) => {
    setInvoiceLoading(true)
    setShowInvoicePreview(true)

    var bodyData = {}
    bodyData.mapUrl = mapUrl
    bodyData.description = description
    bodyData.selectedImages = selectedImages
    bodyData._idf_job = props.location.state.__ID
    bodyData.materialList = roof.materialList.filter(
      material => material.name !== ''
    )
    bodyData.laborData = laborData
    bodyData.type = type
    bodyData.salesperson = salesperson
    bodyData.features = features.map(feature => feature.getProperty('id'))
    bodyData.showTax = taxChecked === true ? 1 : ''
    bodyData.totalCost = total()
    if (trips[0].qty != '0') {
      bodyData.trips = trips
    }
    bodyData.miscItems = miscItems
    createNewInvoice(bodyData)
  }

  const saveInvoice = async id => {
    console.log('id', id)
    setInvoiceSaving(true)
    try {
      const token = await getTokenSilently()
      const response = await fetch(appPaths.apiPath + '/api/saveInvoice', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json', // sent request
          Accept: 'application/json' // expected data sent back
        },
        body: JSON.stringify({ __ID: id, _idf_job: props.location.state.__ID })
      })

      const responseData = await response.json()

      setInvoiceSaving(false)

      if (responseData.message) {
        let data = {
          set: true,
          to: { pathname: '/job', state: { __ID: props.location.state.__ID } }
        }
        setRedirectDetails(data)
      } else {
        alert('Error saving invoice. Please try again.')
      }
    } catch (error) {
      console.error(error)
    }
  }

  const createNewInvoice = async body => {
    console.log(body, 'body')
    try {
      const token = await getTokenSilently()

      await fetch(
        appPaths.apiPath + '/api/updateJobMaterials',
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json', // sent request
            Accept: 'application/json' // expected data sent back
          },
          body: JSON.stringify({ jobId: props.location.state.__ID, oldMaterials, newMaterials: roof.materialList })
        }
      );

      await getOldMaterials();
      
      const response = await fetch(appPaths.apiPath + '/api/createInvoice', { // HPDS Rennel Parino 11/29/2022 - need to know where the PDF I/O is.
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json', // sent request
          Accept: 'application/json' // expected data sent back
        },
        body: JSON.stringify(body)
      })

      const responseData = await response.json()
      if (responseData.message) {
        if (permissionType.includes('type:admin')) {
          //window.open(responseData.invoice.url);
          setPreviewUrl(responseData.invoice.url)
          setInvoiceId(responseData.invoice.id)
        } else {
          saveInvoice(responseData.invoice.id)
        }

        setInvoiceLoading(false)

        /*
          let data = {set: true, to: {pathname: "/job", state: {__ID: props.location.state.__ID}}};
          setRedirectDetails(data);
          */
      } else {
        setInvoiceLoading(false)
        //alert(responseData);
      }
    } catch (error) {
      console.error(error)
    }
  }

  const total = () => {
    let total = 0
    laborData.forEach(row => {
      total += lineTotal(row.hours, row.rate)
    })

    roof.materialList.forEach(row => {
      total += lineTotal(row.qty, row.price)
    })

    trips.forEach(row => {
      total += lineTotal(row.qty, row.price)
    })

    miscItems.forEach(row => {
      total += lineTotal(row.qty, row.price)
    })

    return total
  }

  const totalDirectCost = () => {
    let total = 0
    laborData.forEach(row => {
      total += lineTotal(row.hours, row.direct_cost)
    })

    roof.materialList.forEach(row => {
      total += lineTotal(row.qty, row.direct_cost)
    })

    trips.forEach(row => {
      total += lineTotal(row.qty, row.direct_cost)
    })

    miscItems.forEach(row => {
      total += lineTotal(row.qty, row.direct_cost)
    })

    return total
  }

  const lineTotal = (quantity, cost) => {
    if (
      !quantity ||
      quantity === '' ||
      isNaN(quantity) ||
      !cost ||
      cost === '' ||
      isNaN(cost)
    ) {
      return 0
    } else {
      let total = parseFloat(quantity) * parseFloat(cost)
      return total
    }
  }

  useEffect(() => {
    features.forEach(feature => {
      images.forEach(image => {
        if (feature.getProperty('id') == image.fieldData._idf_marker) {
          image.label = feature.getProperty('label')
        }
      })
    })
  }, [features, images])

  return (
    <div style={{ width: '100%' }}>
      {redirectDetails.set && <Redirect push to={redirectDetails.to} />}
      {goBack && <Redirect push to={redirectDetails.to} />}
      {invoiceSaving && (
        <div style={{ textAlign: 'center' }}>
          {' '}
          <CircularProgress />{' '}
        </div>
      )}
      {!invoiceSaving && (
        <>
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              backgroundColor: 'white',
              color: 'black',
              padding: '15px'
            }}
          >
            {jobDetails !== null &&
              jobDetails.Customer_lu +
                ' - ' +
                jobDetails.Job_Name +
                ' - ' +
                jobDetails.Number_JobCombined}
          </div>
          <MobileStepper
            variant='dots'
            steps={steps.length}
            position='static'
            activeStep={activeStep}
            className={classes.root}
            nextButton={
              <Button
                size='small'
                onClick={handleNext}
                disabled={activeStep === 4}
              >
                Next
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button size='small' onClick={handleBack}>
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          />
          <Stepper
            activeStep={activeStep}
            className={classes.stepper}
            alternativeLabel
          >
            {steps.map((label, i) => (
              <Step
                key={label}
                onClick={() => {
                  setActiveStep(i)
                }}
              >
                <StepButton>{label}</StepButton>
              </Step>
            ))}
          </Stepper>
          <Paper
            className={classes.sectionMobile}
            style={{ borderRadius: '0px' }}
          >
            <Typography
              variant='h5'
              style={{ width: '100%', textAlign: 'center' }}
            >
              {getSteps()[activeStep]}
            </Typography>
          </Paper>

          <div style={{ display: activeStep == 0 ? 'flex' : 'none' }}>
            <InvoiceMarkers
              features={features}
              setFeatures={setFeatures}
              setZoom={setZoom}
              setCenter={setCenter}
              setMapRef={setMapRef}
              setImageMapData={setImageMapData}
              addImages={addImages}
              removeImages={removeImages}
              selectedImages={selectedImages}
              jobId={props.location.state.__ID}
            />
          </div>
          <div style={{ display: activeStep == 1 ? 'flex' : 'none' }}>
            <InvoiceImages
              imageMapData={imageMapData}
              features={features}
              images={images}
              changeImages={setImages}
              changeSelectedImages={val => setSelectedImages(val)}
              selectedImages={selectedImages}
              jobId={props.location.state.__ID}
            />
          </div>
          <div style={{ display: activeStep == 2 ? 'flex' : 'none' }}>
            <InvoiceMaterials
              materialList={roof.materialList}
              setMaterialList={setMaterialList}
              zoom={zoom}
              tab={activeStep}
              center={center}
              mapData={imageMapData}
              selectedImages={selectedImages}
              mapRef={mapRef}
              jobId={props.location.state.__ID}
            />
          </div>
          <div style={{ display: activeStep == 3 ? 'flex' : 'none' }}>
            <InvoiceAccounting
              materialList={roof.materialList}
              setMaterialList={setMaterialList}
              taxChecked={taxChecked}
              setTaxChecked={setTaxChecked}
              miscItems={miscItems}
              setMiscItems={setMiscItems}
              trips={trips}
              setTrips={setTrips}
              timecards={timecards}
              setTimecards={setTimecards}
              laborData={laborData}
              setLaborData={setLaborData}
              zoom={zoom}
              tab={activeStep}
              center={center}
              mapData={imageMapData}
              selectedImages={selectedImages}
              mapRef={mapRef}
              jobId={props.location.state.__ID}
              total={total}
              totalDirectCost={totalDirectCost}
              lineTotal={lineTotal}
            />
          </div>
          <div style={{ display: activeStep == 4 ? 'flex' : 'none' }}>
            <InvoiceOverview
              features={features}
              createInvoice={createInvoice}
              zoom={zoom}
              tab={activeStep}
              center={center}
              mapData={imageMapData}
              selectedImages={selectedImages}
              mapRef={mapRef}
              jobId={props.location.state.__ID}
            />
          </div>

          <InvoicePreview
            open={showInvoicePreview}
            setOpen={setShowInvoicePreview}
            url={previewUrl}
            setUrl={setPreviewUrl}
            loading={invoiceLoading}
            setLoading={setInvoiceLoading}
            saveInvoice={saveInvoice}
            invoiceId={invoiceId}
          />
        </>
      )}
      {/*invoiceLoading === 0 && <CircularProgress></CircularProgress>}
      {invoiceLoading === 1 && redirectDetails.set && <Redirect push to={redirectDetails.to} />}
        {invoiceLoading === 2 && <div>Invoice Creation Failed. Please Try again</div>*/}
    </div>
  )
}
