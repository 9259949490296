import React, {useState} from "react";
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';
import { useAuth0 } from "../../../react-auth0-wrapper";
import appPaths from '../../../applicationPaths.json';
// import styles from './opportunitiesPhoto.module.css';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({

    opportunities_Icon: {
        fontSize:'21px',
        marginLeft:'4px',
    },

    save_button: {
        marginLeft:'4px',
        [theme.breakpoints.down('xs')]: {
            margin:'4px 0',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft:'4px',
        },
    }

  }));


const SaveBtn = (props) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const { getTokenSilently } = useAuth0();

    //Performs an HTTP get request to the server
    //Returns a JSON object of the result
    const fetchCommand = async (url, data) => {
        const token = await getTokenSilently();
        const request = {
            method: 'POST',
            headers: { 
                Authorization: 'Bearer ' + token,
                Accept: 'application/json'
            },
            body: data
        };
        const result = await fetch(url, request);
        const obj = await result.json();
        return obj;
    }

    const saveMapData = async (opid, data) => {
       
        try {
            const path = appPaths.apiPath + '/api/opportunities/map/' + opid;
            const result = await fetchCommand(path, data);

            return result.message;
        } catch(error) {
            console.error(error);
            return false;
        }
    }

    const getGeoJson = (map) => {
        let promise = new Promise((resolve, reject) => {
            map.data.toGeoJson(object => {
                resolve(object);
            });
        });


        return promise;
    }

    const handleClick = async () => {
        setLoading(true);
        props.setMessage('Saving to FileMaker...');

        //Format all the data
        let geoJson = await getGeoJson(props.map);

        let obj = {
            mapData : geoJson,
            markers : props.markers,
            center : props.map.getCenter()
        }

        let data = JSON.stringify(obj);
    
        //Save Map data to server
        let result = await saveMapData(props.opportunity.recordId, data);

        //Display result to user
        if(result) {
            props.setMessage('Changes saved.');
        } else {
            props.setMessage('Error saving. Please try again.');
        }

        //Hide result after 5 seconds
        setTimeout(()=> {
            props.setMessage(null);
        }, 5000);

        setLoading(false);
    }

    const element = (
        <Button variant="contained" color="primary" onClick={handleClick} className={classes.save_button}>
            {props.isTabletOrMobile ? 'Save' : 'Save Changes'}
            {!loading && <SaveIcon className={classes.opportunities_Icon}/>}
            {loading && <CircularProgress size={20} thickness={4} style={{color: 'white'}}/>}
        </Button>
        
    );

    return element;
}

export default SaveBtn;