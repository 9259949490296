import React, { Component } from 'react';
import styles from './contactDuraroof.module.css';
import logo from '../../../assets/images/logo.png';
import Button from '@material-ui/core/Button';
import { FormControl, InputLabel, Input, CircularProgress } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import appPaths from '../../../applicationPaths.json';
import Zoom from '@material-ui/core/Zoom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


// const useStyles = makeStyles(theme => ({

//   contactform_secondaryButton: {
//       backgroundColor: '#535b69',
//       color: '#fff',
//       width: '100%',
//       marginTop: '12px',
//       fontSize:'14px',
//       '&:hover': {
//           backgroundColor: '#494f5a',
//       }
//   }

// }));


// export default function ContactDuraroof(props)
// {
//     const classes = useStyles();
// }


class List extends Component {
  
  // Initialize the state
  constructor(props){
    super(props);
    this.state = {
      list: {
        companyEmail : '',
        companyPhone : '',
        contactPhone : '',
        contactType : 'Company Email',
        companyName : '',
        description : '',
        contactFirstName : '',
        contactLastName : '',
        building : '',
        lockboxCode : '',
        ladder : false
      },
      message: null,
      loading : false,

    }
    this.handleChange = this.handleChange.bind(this);
  }


  // Fetch the list on first mount
  submitTicket()
  {
    
    if(this.state.list.companyName != '' &&  this.state.list.contactFirstName != '' && this.state.list.contactLastName != '' && (this.state.list.companyEmail != '' || this.state.list.companyPhone != '' || this.state.list.contactPhone != ''))
    {
      this.setState({ loading : true });
      this.postData(this.state.list);
    }
    else
    {
      alert('You must fill out your name, company name and at least one contact field!');
    }

  }
  postData(contactDetails) {
    fetch(appPaths.apiPath + '/api/submitTicket', {
      method :'post',
      mode:'cors',
      headers: {
        'Content-Type':'application/json',  // sent request
        'Accept':'application/json'   // expected data sent back
      },
      body: JSON.stringify(contactDetails)
  })
    .then(res => res.json())
    .then(message => {
      this.setState({ message });
      this.setState({ loading : false });
      if(message.message)
      {
        alert("Leak successfully reported. We will be reaching out shortly!");
        this.props.history.push('/');
      }
      else
      {
        this.setState({ loading : false });
        alert("An error occured during submission. Please try again!");
        
      }
    })
    .catch(status => {
      alert("An error occured! Please try again!");

    })
  }
  handleChange(event) {
    console.log(event.target);
    let value = this.state;
    value.list[event.target.name] = event.target.value;
    this.setState(value);
    console.log(this.state);
  }

  render() {

    const contact_secondaryButton = {
        backgroundColor: '#535b69',
        color: '#fff',
        width: '100%',
        fontSize:'14px',
        '&:hover': {
            backgroundColor: '#494f5a',
        }
    };

    let options = [
      <option key="0" value="Company Email">Company Email</option>,
      <option key="1" value="Company Phone">Company Phone</option>,
      <option key="2" value="Contact Phone">Contact Phone</option>
    ];

    return (
      
      <div className={styles.contactform_wrapper}>
        
        <Zoom in={true} 
        {...({ timeout: 500})}>
          
        <Paper className={styles.contactform_innerWrapper}>
        
          {!this.state.loading && <>
            <img src={logo} className={styles.contactform_logo} alt="logo" />

            <div>
              <h2 className={styles.contactform_heading}>Add Opportunity/Roof Inspection</h2>
              <p className={styles.contactform_required}>* = required</p>
            </div>
            {/* Check to see if any items are found*/}
          
            <FormControl>
              <InputLabel> Description of a Problem <span className={styles.contactform_required}>*</span> </InputLabel>
              <Input required type="text" className={styles.contact_input} placeholder="Enter Description of the Problem" name="description" value={this.state.list.description} onChange={this.handleChange}/>
            </FormControl>
              
            <FormControl>
              <InputLabel> Company/Customer Name <span className={styles.contactform_required}>*</span> </InputLabel>
              <Input required type="text" className={styles.contact_input} placeholder="Enter Company Name" name="companyName" value={this.state.list.companyName} onChange={this.handleChange}/>
            </FormControl>
              
            <FormControl>
              <InputLabel> Company Email <span className={styles.contactform_required}>*</span> </InputLabel>
              <Input required type="email" className={styles.contact_input} placeholder="Enter email" name="companyEmail" value={this.state.list.companyEmail} onChange={this.handleChange}/>
            </FormControl>
              
            <FormControl>
              <InputLabel>Company Phone <span className={styles.contactform_required}>*</span></InputLabel>
              <Input required type="tel" className={styles.contact_input} placeholder="Enter Phone" name="companyPhone" value={this.state.list.companyPhone} onChange={this.handleChange}/>
            </FormControl>
            
            <FormControl>
              <InputLabel>Contact Person First Name <span className={styles.contactform_required}>*</span></InputLabel>
              <Input required type="text" className={styles.contact_input} placeholder="Enter First Name" name="contactFirstName" value={this.state.list.contactFirstName} onChange={this.handleChange}/>
            </FormControl>

            <FormControl>
              <InputLabel>Contact Person Last Name  <span className={styles.contactform_required}>*</span></InputLabel>
              <Input required type="text" className={styles.contact_input} placeholder="Enter Last Name" name="contactLastName" value={this.state.list.contactLastName} onChange={this.handleChange}/>
            </FormControl>
            
            <FormControl>
              <InputLabel>Contact Person Phone</InputLabel>
              <Input type="tel" className={styles.contact_input} placeholder="EnterPhone" name="contactPhone" value={this.state.list.contactPhone} onChange={this.handleChange}/>
            </FormControl>
              
            <FormControl>
              <InputLabel className={styles.contact_label}>Please select which way you would like to be contacted</InputLabel>
              <Select className={styles.contact_input}
                native
                  value={this.state.list.contactType}
                  onChange={this.handleChange}
                    input={<Input id="contactType" name="contactType" />}>
                {options}
                </Select>
            </FormControl>

            <FormControl>
              <InputLabel>Name of Building/Tenant:</InputLabel>
              <Input type="text" className={styles.contact_input} placeholder="Building/Tenant" name="building" value={this.state.list.building} onChange={this.handleChange}/>
            </FormControl>
              
            <FormControl>
              <InputLabel>Roof Access Info/Lockbox Code:</InputLabel>
              <Input type="text" className={styles.contact_input} placeholder="Roof Access Info/Lockbox Code" name="lockboxCode" value={this.state.list.lockboxCode} onChange={this.handleChange}/>
            </FormControl>

            <FormControl>
              <FormControlLabel className={styles.contact_input} control={
                <Checkbox
                  checked={this.state.list.ladder}
                  onChange={(event) => {
                    let value = this.state;
                    value.list[event.target.name] = event.target.checked;
                    this.setState(value);
                  }}
                  name="ladder"
                  inputProps={{
                    'aria-label': 'primary checkbox',
                  }}
                />
              } label="Ladder Needed?" />
            </FormControl>

            {/* <Button variant="contained" className={styles.contactform_secondaryButton} onClick={() => this.submitTicket()}> */}
            <Button variant="contained" onClick={() => this.submitTicket()} style={contact_secondaryButton}>
              Submit
            </Button>
            </>
            }
            
            {this.state.loading && <CircularProgress></CircularProgress>}
        </Paper>
        
        {/* <BottomNavBar /> */}

        </Zoom>

      </div>
    
    );
  }
}

export default List;