import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth0 } from '../../react-auth0-wrapper'
import { Modal, Card, TextField, Button, Select, MenuItem } from '@material-ui/core';
import theme from '../../themes/theme';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { config } from '../../config'


const useStyles = makeStyles(theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow:'scroll',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

  

export default function ImageModal(props)
{
    const classes = useStyles();
    const {
      isAuthenticated,
      loginWithRedirect,
      logout,
      getTokenSilently
    } = useAuth0()
    const [editing, setEditing] = useState(false);
    const [description, setDescription] = useState("");
    const [captionList, setCaptionList] = useState([])
    const [caption, setCaption] = useState('')


    useEffect(() => {
      const getCaptions = async () => {
        try {
          const token = await getTokenSilently()
          const response = await fetch(config.API_ENDPOINT + '/api/getCaptionList', {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json', // sent request
              Accept: 'application/json' // expected data sent back
            }
          })
          const responseData = await response.json()
          console.log(responseData.data)
          if (responseData.message) {
            setCaptionList(responseData.data.response.data)
          } else {
            //alert(responseData);
          }
        } catch (error) {
          console.error(error)
        }
      }
      getCaptions()
    }, [getTokenSilently])

  const handleCaptionChange = event => {
    setDescription(event.target.value)
  }

  const handleClose = () => {
    props.changeOpen(false);
  };

    return (
      <>
      {props.image != null &&
        <Modal
        className={classes.modal}
        open={props.open}
        onClose={handleClose}
        >
          
            <Card style={{width: '80%', height: '80%', margin: theme.spacing(2), display: 'flex', textAlign: 'center', flexDirection:'column'}}>
                <div style={{width: '100%', height: '100%', backgroundImage: 'url(' + props.image.fieldData.url + ')', backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat'}}>
                </div>
                    {/*<img alt={props.image.Description} style={{}} src={props.image.Picture_oAe} />*/}
                    {!editing && props.editable &&
                    /* This is the modal that allows the photo to be viewed, and its caption editable.
                    
                    Removed logic:
                    disabled={props.image.fieldData.BeforeAfter == 'Before' || props.image.fieldData.BeforeAfter == 'After'}
                    */
                    <>
                      <div style={{margin: theme.spacing(2)}}>
                        <div>{props.image.fieldData.Description}</div>
                        <Button variant="contained" color="primary"  onClick={() => {setEditing(true); setDescription(props.image.fieldData.Description)}}>
                          <EditIcon />
                          Edit
                        </Button>
                      </div>
                      
                      </>
                    }
                    {/*HPDS Rennel Parino 8/26/2024 - The textfield needs to be replaced with a select element, with the values and labels of what is in the
                    Upload Job Image component
                    Check props.image.fieldData.BeforeAfter as condition of what to pull.

                    material.fieldData.__ID variable needs to be substituted for the proper paramater name

                    try props.image.fieldData.__ID

                    Base Code

                    {props.image.fieldData.BeforeAfter === 'Before' &&
                <Select
                  className={classes.jobFullWidth}
                  onChange={handleCaptionChange}
                  value={caption}
                >
                  {captionList.filter(caption => caption.fieldData.flag_before === '1')
                  .map(material => <MenuItem key={props.image.fieldData.__ID} value={material.fieldData.caption}>{material.fieldData.caption}</MenuItem>)}
                </Select>
              }

              {
              props.image.fieldData.BeforeAfter === 'After' &&
                <Select
                  className={classes.jobFullWidth}
                  onChange={handleCaptionChange}
                  value={caption}
                >
                  {captionList.filter(caption => caption.fieldData.flag_before === '0')
                  .map(material => <MenuItem key={material.fieldData.__ID} value={material.fieldData.caption}>{material.fieldData.caption}</MenuItem>)}
                </Select>
              }

              Orig Code

                    <TextField style={{width:'100%'}} multiline value={description} onChange={(event) => setDescription(event.target.value)} ></TextField>
                    */}
                    {editing && props.editable &&
                    <>
                      <div style={{margin: theme.spacing(2)}}>
                      {props.image.fieldData.BeforeAfter === 'Before' &&
                <Select
                  className={classes.jobFullWidth}
                  onChange={handleCaptionChange}
                  value={description}
                >
                  {captionList.filter(caption => caption.fieldData.flag_before === '1')
                  .map(material => <MenuItem key={props.image.fieldData.__ID} value={material.fieldData.caption}>{material.fieldData.caption}</MenuItem>)}
                </Select>
              }

              {
              props.image.fieldData.BeforeAfter === 'After' &&
                <Select
                  className={classes.jobFullWidth}
                  onChange={handleCaptionChange}
                  value={description}
                >
                  {captionList.filter(caption => caption.fieldData.flag_before === '0')
                  .map(material => <MenuItem key={material.fieldData.__ID} value={material.fieldData.caption}>{material.fieldData.caption}</MenuItem>)}
                </Select>
              }

              {
              props.image.fieldData.BeforeAfter != 'Before' && props.image.fieldData.BeforeAfter != 'After' &&
              <TextField style={{width:'100%'}} multiline value={description} onChange={(event) => setDescription(event.target.value)} ></TextField>
              }
                        <Button variant="contained" color="primary" onClick={() => setEditing(false)}>
                          <CloseIcon />
                          Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => {setEditing(false); props.changeDescription(description)}}>
                          <DoneIcon />
                          Save
                        </Button>
                      </div>
                      
                      </>
                    }
                    {!props.editable &&
                    <>
                      <div style={{marginBottom: '25px'}}>{props.description}</div>
                    </>}
                    
                

            </Card>

        </Modal>
      }
      </>
    );
}