import React, {useState, useEffect} from "react";
import { useAuth0 } from "../../../react-auth0-wrapper";
import appPaths from '../../../applicationPaths.json';
import EditDialog from './EditDialog';
import GridList from '@material-ui/core/GridList';
import {CircularProgress} from '@material-ui/core';
import GridListTile from '@material-ui/core/GridListTile';

const PhotoList = (props) => {
    const { getTokenSilently } = useAuth0();


    useEffect(() => {
        //when a new report is selected, load the photos for this report
        loadPhotos(props.selectedReport.__ID);
    }, [props.selectedReport]);


    useEffect(() => {
        //If set to reload, load photos again
        if(props.reloadPhotos) {
            loadPhotos(props.selectedReport.__ID);
            props.setReloadPhotos(false);
        }
    }, [props.reloadPhotos]);

    //Formats data from FileMaker to include the Record ID and Field Data in a JavaScript Array
    const formatList = (data) => {
        let list = data.map(item => {
            item.fieldData.recordId = item.recordId;
            return item.fieldData;
        });
        return list;
    }

    const fetchCommand = async (url, method = '') => {
        const token = await getTokenSilently();
        const headers = { headers: { Authorization: 'Bearer ' + token } };
        const result = await fetch(url, headers);
        const obj = await result.json();
        return obj;
    }

    const deleteCommand = async (url) => {
        const token = await getTokenSilently();
        const request = {
            method: 'DELETE',
            headers: { 
                Authorization: 'Bearer ' + token,
                Accept: 'application/json'
            },
            body: {}
        };
        const result = await fetch(url, request);
        const obj = await result.json();
        return obj;
    }

    const updateCommand = async (url, data) => {
        const token = await getTokenSilently();
        const request = {
            method: 'POST',
            headers: { 
                Authorization: 'Bearer ' + token,
                Accept: 'application/json'
            },
            body: JSON.stringify(data)
        };

        const result = await fetch(url, request);
        const obj = await result.json();
        return obj;
    }

    const loadPhotos = async (selectedReportId) => {
        try {
            props.setLoadingPhotos(true);
            const path = appPaths.apiPath + '/api/dailyReport/' + selectedReportId + '/photos';
            const result = await fetchCommand(path);

            console.log(result);

            //if data successfully fetched
            if(result.message) {
                
                let list = formatList(result.data);
                props.setPhotos(list);
                props.setLoadingPhotos(false);
            } else {
                props.setPhotos([]);
                props.setLoadingPhotos(false);
            }
        } catch(error) {
            console.error(error);
        }
    }

    const deletePhoto = async (value) => {
        if(window.confirm('Are you sure you want to delete this photo?'))
            try {
                props.setLoadingPhotos(true);
                const path = appPaths.apiPath + '/api/dailyReport/' + props.selectedReport.__ID + '/photos/' + value;
                const result = await deleteCommand(path);

                //if data successfully fetched
                if(result.message) {
                    props.setReloadPhotos(true);
                    props.setEditOpen(false);
                } else {
                    props.setLoadingPhotos(false);
                    props.setEditOpen(false);
                }
            } catch(error) {
                console.error(error);
            }

    }

    const editPhoto = (photo) => {
        props.setSelectedPhoto(photo);
        props.setEditOpen(true);
    }

    const updateDescription = async (description) => {
        try {
            props.setLoadingPhotos(true);
            const path = appPaths.apiPath + '/api/dailyReport/' + props.selectedReport.__ID + '/photos/' + props.selectedPhoto.__ID;
            const result = await updateCommand(path, { description : description });

            //if data successfully fetched
            if(result.message) {
                props.setReloadPhotos(true);
            } else {
                props.setLoadingPhotos(false);
            }
        } catch(error) {
            console.error(error);
        }
    }

    return (
        <>
        <div>
            {/* <br /> */}

            <GridList cellHeight={160} cols={2}>
                {props.photos.map(photo => (
                <GridListTile key={photo.recordId} cols={1}>
                    <img src={photo.base64Img} onClick={e => editPhoto(photo)} />
                </GridListTile>
                ))}
            </GridList>

            {/* Loading */}
            {props.loadingPhotos && <div><CircularProgress></CircularProgress></div>}
        </div>
        <EditDialog
            dialogOpen={props.editOpen}
            setDialogOpen={props.setEditOpen}
            selectedPhoto={props.selectedPhoto}
            setSelectedPhoto={props.setSelectedPhoto}
            updateDescription={updateDescription}
            deletePhoto={deletePhoto}
        />
        </>
    );
}

export default PhotoList;


