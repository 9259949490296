import React, { useState, useEffect, forwardRef } from 'react'
import { Redirect } from 'react-router'
import { useAuth0 } from '../../../react-auth0-wrapper'
import appPaths from '../../../applicationPaths.json'
import Loading from './Loading'
import Header from './Header'
import ActionDetail from './ActionDetail'
import RoofSectionMap from '../Map/RoofSectionMap'
import Message from './Message'
import { useMediaQuery } from 'react-responsive'

import Edit from './Edit'

const Opportunity = props => {
  const [loading, setLoading] = useState(true)
  const {
    match: { params }
  } = props
  const { getTokenSilently } = useAuth0()
  const [opportunity, setOpportunity] = useState({})
  const [redirect, setRedirect] = useState(false)
  const [message, setMessage] = useState(null)
  const [map, setMap] = useState(null)
  const [markers, setMarkers] = useState([])
  const [snapshotSize, setSnapshotSize] = useState({ height: 625, width: 625 })
  const [editMode, setEditMode] = useState(false)
  const [editIndex, setEditIndex] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [selectedLabel, setSelectedLabel] = useState(null)
  const [textBoxFocus, setTextBoxFocus] = useState(false)
  const [editFeature, setEditFeature] = useState(null)
  const [displayFrame, setDisplayFrame] = useState(true)
  const [photos, setPhotos] = useState([])
  const [loadingPhotos, setLoadingPhotos] = useState(false)
  const [reloadPhotos, setReloadPhotos] = useState(false)

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const isExtraSmall = useMediaQuery({ query: '(max-width:398px)' })

  const [roofTypes, setRoofTypes] = useState([
    { id: 1, name: 'BUR' },
    { id: 2, name: 'TPO rhino bond' }
    // { id: 3, name: 'EPDM ballast' },
    // { id: 4, name: 'TPO fully adhered' },
    // { id: 5, name: 'EPDM fully adhered' }
  ])

  const [problemList, setProblemList] = useState([])

  useEffect(() => {
    getData()
    getRoofTypes()
    getProblemList()
  }, [getTokenSilently])

  //Performs an HTTP get request to the server
  //Returns a JSON object of the result
  const fetchCommand = async url => {
    const token = await getTokenSilently()
    const headers = { headers: { Authorization: 'Bearer ' + token } }
    const result = await fetch(url, headers)
    const obj = await result.json()
    return obj
  }

  //Get data from the database
  const getData = async () => {
    try {
      const path = appPaths.apiPath + '/api/opportunities/' + params.opid
      const result = await fetchCommand(path)

      //if data successfully fetched
      if (result.message) {
        setOpportunity(result.data)
        setLoading(false)
      } else {
        setRedirect('/opportunities')
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    //when a new opportunity is selected, load the photos for this opportunity
    loadPhotos(opportunity.id)
    // getData()
  }, [opportunity])

  const isMarkerSaved = value => {
    if (value) {
      getData()
    }
  }
  useEffect(() => {
    //If set to reload, load photos again
    if (reloadPhotos) {
      loadPhotos(opportunity.id)
      setReloadPhotos(false)
    }
  }, [reloadPhotos])

  //Formats data from FileMaker to include the Record ID and Field Data in a JavaScript Array
  const formatList = data => {
    let list = data.map(item => {
      item.fieldData.recordId = item.recordId
      return item.fieldData
    })
    return list
  }

  const loadPhotos = async id => {
    try {
      setLoadingPhotos(true)
      const path = appPaths.apiPath + '/api/opportunities/' + id + '/photos'
      const result = await fetchCommand(path)

      console.log('loadPhotos result:', result)

      //if data successfully fetched
      if (result.message) {
        let list = formatList(result.data)
        setPhotos(list)
        setLoadingPhotos(false)
      } else {
        setPhotos([])
        setLoadingPhotos(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getRoofTypes = async () => {
    try {
      const path = appPaths.apiPath + '/api/settings/rooftypes'
      const result = await fetchCommand(path)

      //if data successfully fetched
      // if (result.message) {
      setRoofTypes(result.data)
      // }
      console.log(result.message, 'message')
      console.log(result.data)
    } catch (error) {
      console.error(error)
    }
  }

  const getProblemList = async () => {
    try {
      const path = appPaths.apiPath + '/api/settings/problemlist'
      const result = await fetchCommand(path)

      //if data successfully fetched
      if (result.message) {
        setProblemList(result.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  //Handle redirect when user selects an item
  if (redirect) {
    return <Redirect push to={redirect} />
  }

  //Handle loading
  if (loading) {
    return <Loading />
  }

  //Render the view
  return (
    <>
      <Header
        opportunity={opportunity}
        map={map}
        markers={markers}
        setMessage={setMessage}
        snapshotSize={snapshotSize}
        isTabletOrMobile={isTabletOrMobile}
        photos={photos}
      />
      <Message message={message} />
      <Edit
        open={editMode}
        setOpen={setEditMode}
        feature={editFeature}
        setMessage={setMessage}
        setTextBoxFocus={setTextBoxFocus}
        map={map}
        roofapi={isMarkerSaved}
        setMarkers={setMarkers}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        isTabletOrMobile={isTabletOrMobile}
        isMobile={isMobile}
        isExtraSmall={isExtraSmall}
        opportunity={opportunity}
        photos={photos}
        setPhotos={setPhotos}
        loadingPhotos={loadingPhotos}
        setLoadingPhotos={setLoadingPhotos}
        reloadPhotos={reloadPhotos}
        setReloadPhotos={setReloadPhotos}
        roofTypes={roofTypes}
        setRoofTypes={setRoofTypes}
        problemList={problemList}
      />
      <RoofSectionMap
        opportunity={opportunity}
        address={opportunity.address}
        setMessage={setMessage}
        setEditMode={setEditMode}
        setEditFeature={setEditFeature}
        textBoxFocus={textBoxFocus}
        map={map}
        setMap={setMap}
        markers={markers}
        setMarkers={setMarkers}
        displayFrame={displayFrame}
        setDisplayFrame={setDisplayFrame}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        selectedLabel={selectedLabel}
        setSelectedLabel={setSelectedLabel}
        snapshotSize={snapshotSize}
        setSnapshotSize={setSnapshotSize}
        displayFrame={displayFrame}
        isTabletOrMobile={isTabletOrMobile}
        isMobile={isMobile}
        isExtraSmall={isExtraSmall}
      />
    </>
  )
}

export default Opportunity
