import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { Checkbox, FormControlLabel, Button, CircularProgress } from '@material-ui/core';
import appPaths from '../../applicationPaths.json';
import { useAuth0 } from "../../react-auth0-wrapper";
import { Redirect } from 'react-router';
import styles from './createUser.module.css';

const paperStyle = makeStyles(theme => ({
  user_wrapper: {
      width:'40%',
      margin:'0 auto',
      marginTop:'1.5%',
      display:'flex',
      justifyContent:'center',
      flexDirection:'column',
      textAlign:'center',
      
      [theme.breakpoints.down('sm')]: {
          width:'60%',
          margin:'0 auto',
          marginTop:'3%',
          paddingBottom:'3%',
      },

      [theme.breakpoints.down('xs')]: {
          width:'100%',
          marginTop:'0%',
      }
  },

  user_secondaryButton: {
    backgroundColor: '#535b69',
    color: '#fff',
    width: '100%',
    fontSize:'14px',
    marginBottom:'8px',
    '&:hover': {
        backgroundColor: '#494f5a',
    }
  }

}))



export default function CreateUser(props)
{
    const classes = paperStyle()
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [admin, setAdmin] = useState(false);
    const [loading, setLoading] = useState(false);
    const { getTokenSilently } = useAuth0();
    const [redirect, setRedirect] = useState({set: false, to: {pathname: "/", state: {}}})


    const formValid = async (callback) => {
        if(email !== "" && name !== "" && password !== "")
        {
          return true;
        }
        else
        {
          return false;
        }
      }

    const createUser = async () => {
        const result = await formValid();
          if(result)
          {
          setLoading(true);
          try {
            const token = await getTokenSilently();
            const response = await fetch(appPaths.apiPath + "/api/createAuthUser", {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',  // sent request
                'Accept':       'application/json'   // expected data sent back
              },
              body: JSON.stringify({
                email: email,
                name: name,
                password: password,
                role: admin ? "admin" : "tech"
              })
            });
        
            const responseData = await response.json();
            setLoading(false);
            if(responseData.message)
            {
              alert("User Succesfully Created!")
              let data = {set: true, to: {pathname: "/", state: {}}};
              setRedirect(data);
  
            }
            else
            {
                alert("User Creation Failed!");
            }
        
          } catch (error) {
            console.error(error);
          }
        }
        else
        {
            alert("You must fill out all of the fields");
        }
    };


    return (
        <div className={classes.user_wrapper}>
            {redirect.set && 
                (<Redirect push to={redirect.to} />
            )}

            {!loading &&
            
            <Paper className={styles.user_innerWrapper}>
                
                  <h2 className={styles.user_heading}>Create User</h2>
                
                  <FormControl>
                      <InputLabel>Email</InputLabel>
                      <Input className={styles.user_input} required type="text" placeholder="Email" name="email" value={email} onChange={(event => { setEmail(event.target.value) })}/>
                  </FormControl>
                  
                  <FormControl>
                    <InputLabel>Name</InputLabel>
                    <Input className={styles.user_input} required type="text" placeholder="Name" name="name" value={name} onChange={(event => { setName(event.target.value) })}/>
                  </FormControl>
                
                  <FormControl>
                    <InputLabel>Password</InputLabel>
                    <Input className={styles.user_input} required type="password" placeholder="Password" name="password" value={password} onChange={(event => { setPassword(event.target.value) })}/>
                  </FormControl>
                  
                  <FormControl>
                    <FormControlLabel className={styles.user_input} control={
                      <Checkbox
                        checked={admin}
                        onChange={() => {setAdmin(!admin)}}
                        value="admin"
                        inputProps={{
                          'aria-label': 'primary checkbox',
                      }}
                  />
              } label="Admin?" />
              </FormControl>

              <Button className={classes.user_secondaryButton} variant="contained" onClick={() => { createUser() }}>
                Create User
              </Button>
                    
            </Paper>
            }
            {loading && <CircularProgress />}
        </div>
    );
}