import React, { useState, useEffect } from 'react'
import {
  CircularProgress,
  Button,
  Select,
  MenuItem,
  OutlinedInput,
  Zoom
} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import { useAuth0 } from '../../react-auth0-wrapper'
import appPaths from '../../applicationPaths.json'
import { Redirect } from 'react-router'
import UploadCaptionDropdown from './UploadCaptionDropdown'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Alert from '@mui/material/Alert';

const useStyles = makeStyles(theme => ({
  jobContainer: {
    width: '40%',
    marginTop: '1.4%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '0%',
      paddingBottom: '10%'
    }
  },

  jobCard: {
    padding: '25px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignContent: 'center',
    textAlign: 'center'
  },

  jobSpacing: {
    padding: '0 8px',
    marginBottom: '2%'
  },

  hide: {
    display: 'none'
  },

  jobFullWidth: {
    width: '100%',
    height: '40px',
    fontSize: '16px'
  },

  jobHeading: {
    fontSize: '22px',
    fontWeight: '600',
    color: '#000',
    padding: '0',
    margin: '0',
    marginBottom: '4%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '6%'
    }
  },

  jobUploadButton: {
    color: 'white',
    backgroundColor: '#24a0ed',
    width: '100%',
    '&:hover': {
      backgroundColor: '#24a0ed'
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '2%'
    }
  },

  jobSubmit: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '2%'
    }
  }
}))

export default function JobUpload (props) {
  const classes = useStyles()
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    getTokenSilently
  } = useAuth0()
  const [selectedFile, setSelectedFile] = useState(null)
  const [loaded, setLoaded] = useState(true)
  const [buttonText, setButtonText] = useState('Select Image')
  const [isImage, setIsImage] = useState(false)
  const [beforeAfter, setBeforeAfter] = useState('Before')
  const [caption, setCaption] = useState('')
  const [redirectDetails, setRedirectDetails] = useState({
    set: false,
    location: '/job-map'
  })
  const [captionList, setCaptionList] = useState([])
  const [isDisabled, setIsDisabled] = useState(true)
  const [isLargeFile, setIsLargeFile] = useState(false)

  useEffect(() => {
    const getCaptions = async () => {
      try {
        const token = await getTokenSilently()
        const response = await fetch(appPaths.apiPath + '/api/getCaptionList', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json', // sent request
            Accept: 'application/json' // expected data sent back
          }
        })
        const responseData = await response.json()
        console.log(responseData.data)
        if (responseData.message) {
          setCaptionList(responseData.data.response.data)
        } else {
          //alert(responseData);
        }
      } catch (error) {
        console.error(error)
      }
    }
    getCaptions()

    //Set default to After, if roof inspection
    if (props.location.state.flag_RoofInspection) {
      setBeforeAfter('After')
    }
  }, [getTokenSilently])

  const onChangeHandler = event => {
    if (event.target.files.length > 0) {
      if (event.target.files[0].size > 20971520) { // larger than 20MB
        setIsLargeFile(true);
      } else {
        setIsLargeFile(false);
      }
      setSelectedFile(event.target.files[0])
      setButtonText(event.target.files[0].name)
      setIsImage(true)
      setIsDisabled(false)
    } else {
      setSelectedFile(false)
      setButtonText('Select Image')
      setIsImage(false)
    }
  }

  const handleChange = event => {
    setBeforeAfter(event.target.value)
  }

  const handleCaptionChange = event => {
    setCaption(event.target.value)
  }

  const onClickHandler = () => {
    setLoaded(false)
    const data = new FormData()
    data.append('file', selectedFile)
    data.append('_idf_job', props.location.state.__ID)
    data.append('BeforeAfter', beforeAfter)
    data.append('Description', caption)
    if (props.location.state.currentFeature != null) {
      data.append('_idf_marker', props.location.state.currentFeature)
    } else {
      data.append('_idf_marker', '')
    }
    if (isDisabled) {
      alert('Please upload an image')
    }

    uploadImage(data, 0)
  }

  const uploadImage = async (form, i) => {
    try {
      const token = await getTokenSilently()

      const response = await fetch(appPaths.apiPath + '/api/uploadImageFile', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json' // expected data sent back
        },
        body: form
      })

      const responseData = await response.json()
      console.log(responseData)
      if (responseData.data.message || i > 9) {
        if (i > 9) {
          if (
            window.confirm(
              'Image failed to upload! Would you like to try resending it?'
            )
          ) {
            uploadImage(form, 0)
          }
        } else {
          let data = {
            set: true,
            to: {
              pathname: '/job-map',
              state: {
                __ID: props.location.state.__ID,
                flag_RoofInspection: props.location.state.flag_RoofInspection
              }
            }
          }
          setRedirectDetails(data)
        }
      } else {
        uploadImage(form, i + 1)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleTextFieldChange = event => {
    setCaption(event.target.value)
  }

  return (
    <div className={classes.jobContainer}>
      {redirectDetails.set && <Redirect push to={redirectDetails.to} />}

      {loaded && (
        <Card className={classes.jobCard}>
          <Zoom in={true} {...{ timeout: 500 }}>
            <div className={classes.jobSpacing}>
              <h2 className={classes.jobHeading}>Upload Job Image</h2>
              {isLargeFile &&
                <Alert severity="warning">File size larger than 20MB will cause longer load times!</Alert>
              }

              <input
                accept='image/*'
                className={classes.hide}
                id='raised-button-file'
                multiple
                type='file'
                onChange={onChangeHandler}
              />

              <label htmlFor='raised-button-file'>
                <Button
                  variant='contained'
                  color='secondary'
                  component='span'
                  className={classes.jobUploadButton}
                >
                  {buttonText}
                </Button>
              </label>
            </div>
          </Zoom>

          <Zoom in={true} {...{ timeout: 1000 }}>
            <div className={classes.jobSpacing}>
              <Select
                className={classes.jobFullWidth}
                onChange={handleChange}
                value={beforeAfter}
                input={<OutlinedInput name='beforeAfter' id='beforeAfter' />}
              >
                <MenuItem value='Before'>Before</MenuItem>
                <MenuItem value='After'>After</MenuItem>
                <MenuItem value='Other'>Free Entry</MenuItem>
              </Select>
            </div>
          </Zoom>

          <Zoom in={true} {...{ timeout: 1500 }}>
            <div className={classes.jobSpacing}>
              {beforeAfter === 'Before' &&
                <Select
                  className={classes.jobFullWidth}
                  onChange={handleCaptionChange}
                  value={caption}
                >
                  {captionList.filter(caption => caption.fieldData.flag_before === '1')
                  .map(material => <MenuItem key={material.fieldData.__ID} value={material.fieldData.caption}>{material.fieldData.caption}</MenuItem>)}
                </Select>
              }
              {beforeAfter === 'After' &&
                <Select
                  className={classes.jobFullWidth}
                  onChange={handleCaptionChange}
                  value={caption}
                >
                  {captionList.filter(caption => caption.fieldData.flag_before === '0')
                  .map(material => <MenuItem key={material.fieldData.__ID} value={material.fieldData.caption}>{material.fieldData.caption}</MenuItem>)}
                </Select>
              }
              {beforeAfter === 'Other' &&
                <TextField
                  className={classes.jobFullWidth}
                  onChange={handleCaptionChange}
                  value={caption}
                />
              }
              {/* <UploadCaptionDropdown
                className={classes.jobFullWidth}
                changeValue={setCaption}
                value={caption}
                changeChoice={() => {}}
                materials={
                  beforeAfter == 'Before'
                    ? [
                        ...new Set(
                          captionList
                            .filter(
                              caption => caption.fieldData.flag_before == '1'
                            )
                            .map(material => {
                              return material.fieldData.caption
                            })
                        )
                      ]
                    : [
                        ...new Set(
                          captionList
                            .filter(
                              caption => caption.fieldData.flag_before != '1'
                            )
                            .map(material => {
                              return material.fieldData.caption
                            })
                        )
                      ]
                }
                label='Caption'
                placeholder='Caption'
              /> */}
            </div>
          </Zoom>

          <Zoom in={true} {...{ timeout: 2000 }}>
            <div className={classes.jobSpacing}>
              <Button
                onClick={onClickHandler}
                disabled={isDisabled}
                variant='contained'
                color='primary'
                className={classes.jobSubmit}
              >
                Submit
              </Button>
            </div>
          </Zoom>
        </Card>
      )}

      {!loaded && <CircularProgress></CircularProgress>}
    </div>
  )
}
