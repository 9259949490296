import React, { useState, useEffect } from 'react';
import { useAuth0 } from "../../react-auth0-wrapper";
import theme from '../../themes/theme';
import appPaths from '../../applicationPaths.json';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ListItem, Divider } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';


export default function RoofInspectionList(props) {
    const [contentLoading, setContentLoading] = useState(true);
    const [docs, setDocs] = useState([]);
    const { getTokenSilently } = useAuth0();

    const openPdf = (e, url) => {
        e.stopPropagation();
        window.open(url, "_self");

    }

    const convertTime = (time) => {
        time = time.split(':'); // convert to array

        // fetch
        var hours = Number(time[0]);
        var minutes = Number(time[1]);
        var seconds = Number(time[2]);
        
        // calculate
        var timeValue;
        
        if (hours > 0 && hours <= 12) {
          timeValue= "" + hours;
        } else if (hours > 12) {
          timeValue= "" + (hours - 12);
        } else if (hours == 0) {
          timeValue= "12";
        }
         
        timeValue += (minutes < 10) ? ":0" + minutes : ":" + minutes;  // get minutes
        //timeValue += (seconds < 10) ? ":0" + seconds : ":" + seconds;  // get seconds
        timeValue += (hours >= 12) ? " PM" : " AM";  // get AM/PM

        return timeValue;
    }

    const convertDate = (date) => {
        let month_names = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let d = new Date(date);
        let month = month_names[d.getMonth()];
        let day = date.split('/')[1];
        let year = date.split('/')[2];

        return month + ' ' + day + ', ' + year;
    }

    useEffect(() => {
        const callApi = async () => {
            try {
              const token = await getTokenSilently();
              const response = await fetch(appPaths.apiPath + "/api/getJobRoofInspectionDocs", {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',  // sent request
                  'Accept':       'application/json'   // expected data sent back
                },
                body: JSON.stringify({jobId: props.__ID})
              });
              const responseData = await response.json();
              if(responseData.message) {
                console.log(responseData.data);
                setDocs(responseData.data);
                setContentLoading(false);
              }
              else {
                setDocs([]);
                setContentLoading(false);
              }
            } catch (error) {
              console.error(error);
            }
        };

        callApi();
    }, [getTokenSilently, props.__ID]);

    const getSecondaryText = (date) => { 
        var part = date.split(" ");
        var datetime = convertDate(part[0]) + ' ' + convertTime(part[1]);
        return (
          <>
          {datetime}
          </>
        );
      }

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width:'100%'}}>
            {!contentLoading &&
            <>
                <Card style={{width:'100%', display: 'flex', textAlign: 'center', flexDirection:'column'}}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Completed Repaired Roof Inspections</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <List style={{width:'100%'}}>
                                <Divider />
                                {docs.map(doc => (
                                    <div key={doc.__ID}>
                                        <ListItem button disableRipple>
                                            <ListItemText button onClick={(e) => openPdf(e, doc.url)} primary={doc.FileName_ae} secondary={getSecondaryText(doc.z_Creation_TS)}/>
                                            {doc.url && <ListItemIcon>
                                                <IconButton onClick={(e) => openPdf(e, doc.url)}>
                                                    <PictureAsPdfIcon />
                                                </IconButton>
                                            </ListItemIcon>}
                                        </ListItem>
                                        <Divider />
                                    </div>
                                ))}
                            </List>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    
                </Card>
            </>
            }
            {contentLoading && <div style={{width:'100%', display: 'flex', textAlign: 'center', justifyContent:'center', borderRadius: '0px'}}><CircularProgress></CircularProgress></div>}

        </div>
    );

}