import React, { useState, useEffect } from 'react'
import { useAuth0 } from '../../react-auth0-wrapper'
import { createMuiTheme, makeStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import Fab from '@material-ui/core/Fab'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import {
  CircularProgress,
  Card,
  CardContent,
  ListItemText
} from '@material-ui/core'
import theme from '../../themes/theme'
import appPaths from '../../applicationPaths.json'
import openSocket from 'socket.io-client'
import TimecardDetails from './TimecardDetails'
import Compress from 'compress.js'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
// import job from '../../../../server/api/job'

const theme2 = createMuiTheme({
  palette: {
    primary: { main: '#3f51b5' },
    secondary: { main: '#f44336' }
  }
})
function compare (a, b) {
  if (a.fieldData.Job_Name < b.fieldData.Job_Name) {
    return -1
  }
  if (a.fieldData.Job_Name > b.fieldData.Job_Name) {
    return 1
  }
  return 0
}

const useStyles = makeStyles(theme => ({
  fab: {
    margin: theme.spacing(1),
    width: '150px',
    height: '150px',
    fontSize: '20px',
    fontWeight: 'bold'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  card: {
    padding: '0 0 20px',
    minWidth: '275px',
    textAlign: 'center',
    width: '40%',
    marginTop: '1.5%',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '0'
    }
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
}))

export default function Timecard (props) {
  const classes = useStyles()
  const [clockedIn, setClockedIn] = useState(false)
  const [loading1, setLoading1] = useState(true)
  const [infoLoaded, setInfoLoaded] = useState(false)
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    getTokenSilently,
    user,
    loading
  } = useAuth0()

  const [timecardData, setTimecardData] = useState({})
  const [newLoading, setNewLoading] = useState(false)
  const [selectedPhoto, setSelectedPhoto] = useState(null) //useState(props.selectedPhoto)
  const [receiptPhoto, setReceiptPhoto] = useState(null) //useState(props.receiptPhoto)
  const [open, setOpen] = useState(false)
  const [jobs, setJobs] = useState([])
  const [jobsLoaded, setJobsLoaded] = useState(false)

  useEffect(() => {
    const connect = async () => {
      let token = await getTokenSilently()
      const socket = await openSocket(appPaths.apiPath, {
        query: 'token=' + token
      })
      socket.on('update', newData => {
        console.log("socket connection successful")
        setTimecardData(newData.data)
        console.log(JSON.stringify(newData.data))
        localStorage.setItem('timecardData', JSON.stringify(newData.data))
        if (newData.data.fieldData.TimeEnd_t != '') {
          setClockedIn(false)
        } else {
          setClockedIn(true)
        }
      })
    }
    if (!jobsLoaded) {
      const getJobs = async () => {
        try {
          const token = await getTokenSilently()
          const response = await fetch(
            appPaths.apiPath + '/api/getJobsListActive',
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json', // sent request
                Accept: 'application/json' // expected data sent back
              },
              body: null
            }
          )

          const responseData = await response.json()
          if (responseData.message) {
            const newJobs = responseData.data.response.data.sort(compare)
            setJobs(newJobs)
            setJobsLoaded(true)
            console.log(newJobs, 'new jobs')

  

            //setJobDetails(responseData.data.response.data[0].fieldData);
            //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
          } else {
            setJobsLoaded(true)
            //alert(responseData);
          }
        } catch (error) {
          console.error(error)
        }
      }
      getJobs()
    }
    if (!loading && isAuthenticated) {
      connect()
    }
  }, [loading, isAuthenticated, getTokenSilently])

  const compressImage = image => {
    return new Promise((resolve, reject) => {
      //Testing image compression
      const compress = new Compress()

      compress
        .compress([image], {
          size: 0.25, // the max size in MB, defaults to 2MB
          quality: 0.75, // the quality of the image, max is 1,
          maxWidth: 400, // the max width of the output image, defaults to 1920px
          maxHeight: 400, // the max height of the output image, defaults to 1920px
          resize: true // defaults to true, set false if you do not want to resize the image width and height
        })
        .then(compressedImages => {
          console.log('Selected File:', image)
          // returns an array of compressed images
          console.log('Compressed Image Data:', compressedImages)

          let img1 = compressedImages[0]
          let base64str = img1.data
          let imgExt = img1.ext
          let file = Compress.convertBase64ToFile(base64str, imgExt)
          // let file = base64str

          console.log('Converted file:', file)
          resolve(file)
        })
    })
  }

  const changeStatus = () => {
    /* HPDS Rennel Parino 10/25/2023 A - Temporary deprecation per client request
    if (!clockedIn && !selectedPhoto) {
      alert('You must add a photo of yourself on the roof before clocking in.')
      return
    }*/
    
    if (
      (!loading1 && timecardData !== null) ||
      (!loading1 && timecardData.fieldData._idf_job === props.jobType)
    ) {
      localStorage.removeItem('timecardData')
      setLoading1(true)
      clockInOut()
      // createTimecard()  HPDS RENNEL PARINO 6/13/2023 - TESTING DUMMY TIMECARD ON LOAD
     
    } else if (!loading1 && timecardData.fieldData._idf_job !== props.jobType) {
      setLoading1(true)
      createTimecard() //HPDS RENNEL PARINO 11/8/2023 - Uncommenting this causes a duplicate clock-in on the timecard
      
    }
  }

  const clockInOut = async () => {
    console.log(timecardData, 'timecard clock in data')
    //prepare form data
    const formData = new FormData()
    //Compress selected photo
    /* HPDS Rennel Parino 10/25/2023 A - Temporary deprecation per client request
    if (selectedPhoto && !clockedIn) {
      let compressedFile = await compressImage(selectedPhoto)
      let filename = selectedPhoto.name
      formData.append('photo', compressedFile, filename)
    }*/

    // Compress Receipt Photo
    if (receiptPhoto && !clockedIn) {
      let receiptFile = await compressImage(receiptPhoto)
      let fileName = receiptPhoto.name

      formData.append('receipt', receiptFile, fileName)
    }

    formData.append('jsonData', JSON.stringify(timecardData))
    console.log(timecardData, 'timecard data>>>')
    console.log(formData, 'formData')
    try {
      const token = await getTokenSilently()
      const response = await fetch(appPaths.apiPath + '/api/clockInOut', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          //'Content-Type': 'application/json',  // sent request
          Accept: 'application/json' // expected data sent back
        },
        body: formData
      })

      const responseData = await response.json()
      if (responseData.message) {
        setInfoLoaded(false)
      } else {
        setInfoLoaded(true)
      }

      setSelectedPhoto(null)
      setReceiptPhoto(null)
    } catch (error) {
      console.error(error)
    }
  }

  const createTimecard = data => {
    console.log('createTimecard data', data)
    
    /* HPDS Rennel Parino 10/25/2023 A - Temporary deprecation per client request
    if (!selectedPhoto) {
      alert('You must add a photo of yourself on the roof before clocking in.')
      return
    } */

    setNewLoading(true)
    localStorage.removeItem('timecardData')
    setLoading1(true)
    const createTimecard1 = async data => {
      //prepare form data
      const formData = new FormData()

      //Compress selected photo
      /* HPDS Rennel Parino 10/25/2023 A - Temporary deprecation per client request
      if (selectedPhoto) {
        let compressedFile = await compressImage(selectedPhoto)
        let filename = selectedPhoto.name
        formData.append('photo', compressedFile, filename)
      }*/

      //Compress Receipt Photo
      if (receiptPhoto) {
        let receiptFile = await compressImage(receiptPhoto)
        let fileName = receiptPhoto.name
        formData.append('receipt', receiptFile, fileName)
      }

      formData.append('jsonData', JSON.stringify(data))

      for (let pair of formData.entries()) {
        console.log(pair[0] + ', ' + pair[1], 'form data')
      }

      try {
        const token = await getTokenSilently()
        const response = await fetch(appPaths.apiPath + '/api/createTimecard', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            //'Content-Type': 'application/json',  // sent request
            Accept: 'application/json' // expected data sent back
          },
          body: formData
        })

        const responseData = await response.json()
        if (responseData.message) {
          setInfoLoaded(false)
          setNewLoading(false)
          //setJobDetails(responseData.data.response.data[0].fieldData);
          //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
        } else {
          //console.log(responseData);
        }
      } catch (error) {
        console.error(error)
      }
    }
    createTimecard1(data)
  }
  const handleClickOpen = () => {
    setOpen(true)

    console.log(props.selectedPhoto, 'selected photo')
  }
  useEffect(() => {
    if (!infoLoaded) {
      if (localStorage.getItem('timecardData')) {
        setInfoLoaded(true)
        setLoading1(false)
        setTimecardData(JSON.parse(localStorage.getItem('timecardData')))
        if (
          JSON.parse(localStorage.getItem('timecardData')).fieldData
            .TimeEnd_t != ''
        ) {
          setClockedIn(false)
        } else {
          setClockedIn(true)
        }
      }
      const getTimecardData = async () => {
        try {
          const token = await getTokenSilently()
          const response = await fetch(
            appPaths.apiPath + '/api/getTimecardData',
            {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json', // sent request
                Accept: 'application/json' // expected data sent back
              },
              body: null
            }
          )

          const responseData = await response.json()
          if (responseData.message) {
            console.log(responseData.data, 'res data')
            if (responseData.data.fieldData.TimeEnd_t != '') {
              setClockedIn(false)
            } else {
              setClockedIn(true)
            }
            setInfoLoaded(true)
            setLoading1(false)
            localStorage.setItem(
              'timecardData',
              JSON.stringify(responseData.data)
            )
            setTimecardData(responseData.data)
            //setJobDetails(responseData.data.response.data[0].fieldData);
            //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
          } else {
            localStorage.removeItem('timecardData')
            setInfoLoaded(true)
            setTimecardData(null)
            setClockedIn(false)
            //alert(responseData);
          }
        } catch (error) {
          console.error(error)
        }
      }
      getTimecardData()
    }
  }, [getTokenSilently, infoLoaded])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {!newLoading && (
        <>
          <Card className={classes.card}>
            <CardContent>
              {!loading1  && (
                <div
                  style={{
                    color: 'black',
                    fontSize: '20px',
                    fontWeight: '500'
                  }}
                >
                  Welcome, {timecardData.fieldData.NameStaff_lu}!
                </div>
              )}

              {!loading1  && (
                <>
                  <div style={{ marginTop: '25px', marginBottom: '15px' }}>
                    <table>
                      <tbody>
                        <tr>
                          <th
                            style={{
                              textAlign: 'right',
                              padding: '5px',
                              width: '50%'
                            }}
                          >
                            Work Type:
                          </th>
                          <td
                            style={{
                              textAlign: 'left',
                              padding: '5px',
                              width: '50%'
                            }}
                          >
                            <Select
                              value={timecardData.fieldData.WorkType}
                              onChange={event => {
                                setTimecardData({
                                  ...timecardData,
                                  fieldData: {
                                    ...timecardData.fieldData,
                                    WorkType: event.target.value
                                  }
                                })
                              }}
                              inputProps={{
                                name: 'workType',
                                id: 'workType'
                              }}
                            >
                              <MenuItem
                                value='Paver Installation'
                                style={{ fontSize: '15px' }}
                              >
                                Paver Installation
                              </MenuItem>
                              <MenuItem
                                value='RFG Field'
                                style={{ fontSize: '15px' }}
                              >
                                RFG Field
                              </MenuItem>
                              <MenuItem
                                value='RFG Delivery'
                                style={{ fontSize: '15px' }}
                              >
                                RFG Delivery
                              </MenuItem>
                              <MenuItem
                                value='RFG PM'
                                style={{ fontSize: '15px' }}
                              >
                                RFG PM
                              </MenuItem>
                              <MenuItem
                                value='RFG Supervisor'
                                style={{ fontSize: '15px' }}
                              >
                                RFG Supervisor
                              </MenuItem>
                              <MenuItem
                                value='SM Field'
                                style={{ fontSize: '15px' }}
                              >
                                SM Field
                              </MenuItem>
                              <MenuItem
                                value='SM Shop'
                                style={{ fontSize: '15px' }}
                              >
                                SM Shop
                              </MenuItem>
                              <MenuItem
                                value='SM Delivery'
                                style={{ fontSize: '15px' }}
                              >
                                SM Delivery
                              </MenuItem>
                              <MenuItem
                                value='SM PM'
                                style={{ fontSize: '15px' }}
                              >
                                SM PM
                              </MenuItem>
                            </Select>
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{
                              textAlign: 'right',
                              padding: '5px',
                              width: '50%'
                            }}
                          >
                            Job:
                          </th>
                          <td
                            style={{
                              textAlign: 'left',
                              padding: '5px',
                              width: '50%'
                            }}
                          >
                            {!clockedIn ? (
                              <Select
                                inputProps={{
                                  name: 'NameJob_lu',
                                  id: 'NameJob_lu'
                                }}
                              >
                                {jobs.map(job => {
                                  return (
                                    <MenuItem
                                      key={job.fieldData.__ID}
                                      value={job.fieldData.__ID}
                                    >
                                      <ListItemText
                                        primary={job.fieldData.Job_Name}
                                        secondary={job.fieldData.Customer_lu}
                                        onClick={() =>
                                          setTimecardData({
                                            ...timecardData,
                                            fieldData: {
                                              ...timecardData.fieldData,
                                              address:
                                                job.fieldData
                                                  .address_Combined_c,
                                              NameJob_lu:
                                                job.fieldData.Job_Name,
                                              _idf_job: job.fieldData.__ID
                                            }
                                          })
                                        }
                                      />
                                    </MenuItem>
                                  )
                                })}
                              </Select>
                            ) : (
                              timecardData.fieldData.NameJob_lu
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{
                              textAlign: 'right',
                              padding: '5px',
                              width: '50%'
                            }}
                          >
                            Address:
                          </th>
                          <td
                            style={{
                              textAlign: 'left',
                              padding: '5px',
                              width: '50%'
                            }}
                          >
                            {timecardData.fieldData.address}
                            {console.log(timecardData, 'time card 570')}
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{
                              textAlign: 'right',
                              padding: '5px',
                              width: '50%'
                            }}
                          >
                            Change Order T&M:
                          </th>
                          <td
                            style={{
                              textAlign: 'left',
                              padding: '5px',
                              width: '50%'
                            }}
                          >
                            {/* {timecardData.fieldData.flag_ChangeOrder === '1'
                              ? 'Yes'
                              : 'No'} */}
                            {!clockedIn ? (
                              <RadioGroup
                                className={classes.timecard_radio}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: '0',
                                  padding: '0'
                                }}
                                aria-label='changeOrder'
                                name='changeOrder'
                                // value={changeOrderTM}
                                onChange={event => {
                                  setTimecardData({
                                    ...timecardData,
                                    fieldData: {
                                      ...timecardData.fieldData,
                                      flag_ChangeOrder: event.target.value
                                    }
                                  })
                                }}
                              >
                                <FormControlLabel
                                  value='1'
                                  control={<Radio />}
                                  label='Yes'
                                />
                                <FormControlLabel
                                  value='0'
                                  control={<Radio />}
                                  label='No'
                                />
                              </RadioGroup>
                            ) : clockedIn &&
                              timecardData.fieldData.flag_ChangeOrder ===
                                '1' ? (
                              'Yes'
                            ) : (
                              'No'
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{
                              textAlign: 'right',
                              padding: '5px',
                              width: '50%'
                            }}
                          >
                            Task:
                          </th>
                          <td
                            style={{
                              textAlign: 'left',
                              padding: '5px',
                              width: '50%'
                            }}
                          >
                            {!clockedIn ? (
                              <input
                                value={timecardData.fieldData.SystemTask_Lu}
                                onChange={e =>
                                  setTimecardData({
                                    ...timecardData,
                                    fieldData: {
                                      ...timecardData.fieldData,
                                      SystemTask_Lu: e.target.value
                                    }
                                  })
                                }
                              />
                            ) : (
                              <p>{timecardData.fieldData.SystemTask_Lu}</p>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div>
                    {!clockedIn && (
                      <TimecardDetails
                        // createTimecard={createTimecard}
                        timecardData={timecardData}
                        selectedPhoto={selectedPhoto}
                        setSelectedPhoto={setSelectedPhoto}
                        receiptPhoto={receiptPhoto}
                        setReceiptPhoto={setReceiptPhoto}
                        changeStatus={changeStatus}
                      />
                    )}
                  </div>
                </>
              )}

              {!loading && !timecardData && (
                <TimecardDetails
                  createTimecard={createTimecard}
                  timecardData={timecardData}
                  selectedPhoto={selectedPhoto}
                  setSelectedPhoto={setSelectedPhoto}
                  receiptPhoto={receiptPhoto}
                  setReceiptPhoto={setReceiptPhoto}
                  changeStatus={changeStatus}
                />
              )}
            </CardContent>
            {timecardData && (
              <>
                <ThemeProvider theme={theme2}>
                  {!clockedIn ? (
                    <Fab
                      onClick={() => {
                        changeStatus()
                      }}
                      style={{
                        background: '#3f51b5',
                        color: 'white',
                        textTransform: 'capitalize'
                      }}
                      className={classes.fab}
                    >
                      {loading1 && (
                        <ThemeProvider theme={theme}>
                          <CircularProgress></CircularProgress>
                        </ThemeProvider>
                      )}
                      {!loading1 && 'CLOCK IN'}
                    </Fab>
                  ) : (
                    <>
                      <Fab
                        onClick={() => changeStatus()}
                        style={{
                          background: '#f44336',
                          color: 'white',
                          textTransform: 'capitalize',
                          borderRadius: '100%'
                        }}
                        className={classes.fab}
                      >
                        {loading1 && (
                          <ThemeProvider theme={theme}>
                            <CircularProgress></CircularProgress>
                          </ThemeProvider>
                        )}
                        {!loading1 && 'CLOCK OUT'}
                      </Fab>
                    </>
                  )}
                </ThemeProvider>
              </>
            )}{' '}
          </Card>
        </>
      )}
      {newLoading && <CircularProgress></CircularProgress>}
    </div>
  )
}
