import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { Button, FormLabel } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import FormControl from '@material-ui/core/FormControl'
import { List, ListItem } from '@material-ui/core'
import lists from '../Individual/individual.module.css'
import { useAuth0 } from '../../../react-auth0-wrapper'
import appPaths from '../../../applicationPaths.json'
import NumberFormat from 'react-number-format'
import LinearProgress from '@material-ui/core/LinearProgress'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import WorkIcon from '@material-ui/icons/Work'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  formControl: {
    width: '100%'
  },
  opportunities_Icon: {
    fontSize: '21px',
    marginLeft: '4px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '2px'
    }
  },

  selectEmpty: {
    marginTop: theme.spacing(2)
  },

  paper: {
    minWidth: '50%',
    [theme.breakpoints.down('sm')]: {
      minWidth: '55%'
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '90%',
      margin: '0 auto'
    }
  },

  action: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0',
    padding: '0',
    borderBottom: '1px solid #acacac',
    marginBottom: '18px'
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '10px',
    color: theme.palette.grey[500],
    [theme.breakpoints.down('sm')]: {
      right: '11px',
      top: '10px'
    },
    [theme.breakpoints.down('xs')]: {
      right: '11px',
      top: '4px'
    }
  },

  generateAction: {
    display: 'flex',
    justifyContent: 'center',
    padding: '18px 24px',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      padding: '18px 24px'
    }
  },

  cancel: {
    width: '50%',
    color: '#fff',
    background: '#535b69',
    '&:hover': {
      background: '#494f5a'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: '2%',
      marginLeft: '0'
    }
  },

  generate: {
    width: '50%',
    color: '#fff',
    background: '#24a0ed',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '8px',
      marginTop: '0'
    },

    '&:hover': {
      background: '#258fd2'
    }
  },

  dialogContentSpacing: {
    padding: '8px 24px',
    [theme.breakpoints.down('xs')]: {
      padding: '8px 24px'
    }
  },

  finalize_button: {
    marginTop: '0',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '4px'
    }
  }
}))

const PdfDialog = props => {
  const [amount, setAmount] = useState('')
  const [numericAmount, setNumericAmount] = useState(0)
  const {
    dialogOpen,
    setDialogOpen,
    selectedOpp,
    setSelectedOpp,
    setSelectedInspection,
    setJobDialogOpen
  } = props
  const classes = useStyles()
  const { getTokenSilently } = useAuth0()
  const [loading, setLoading] = useState(false)
  const [jobCreated, setJobCreated] = useState(false)
  const [newJobId, setNewJobId] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [pdfList, setPdfList] = useState([])

  const handleCloseClick = () => {
    setErrorMessage('')
    setSelectedOpp(null)
    setAmount('')
    setDialogOpen(false)
  }

  const fetchCommand = async url => {
    const token = await getTokenSilently()
    const headers = { headers: { Authorization: 'Bearer ' + token } }
    const result = await fetch(url, headers)
    const obj = await result.json()
    return obj
  }

  const getPdfList = async opportunity_id => {
    setLoading(true)
    try {
      const path =
        appPaths.apiPath + '/api/inspection/' + opportunity_id + '/pdf'
      const result = await fetchCommand(path)
      console.log(path)
      //if data successfully fetched
      if (result.message) {
        console.log(result)
        setPdfList(result.data)
      } else {
        setPdfList([])
        console.log('No PDF found.')
      }
      setLoading(false)
    } catch (error) {
      setPdfList([])
      console.error(error)
    }
  }

  useEffect(() => {
    if (selectedOpp) {
      getPdfList(selectedOpp.__ID)
    }
  }, [selectedOpp])

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseClick}
        aria-labelledby='max-width-dialog-title'
        classes={{ paper: classes.paper }}
      >
        <DialogActions className={classes.action}>
          <DialogTitle
            id='max-width-dialog-title'
            className={lists.generate_title}
          >
            PDFs
          </DialogTitle>

          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={handleCloseClick}
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>

        {!loading && (
          <DialogContent className={classes.dialogContentSpacing}>
            <List style={{ width: '100%' }}>
              {pdfList.length >= 1
                ? pdfList.map(pdf => (
                    <ListItem
                      button
                      disableRipple
                      key={pdf.id}
                      onClick={e => {
                        window.open(pdf.FileName_ae, '_self')
                      }}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <div>
                        <PictureAsPdfIcon /> {pdf.fieldData.FileName_ae} <br />
                        <span style={{ fontSize: '10pt', color: 'gray' }}>
                          {pdf.fieldData.z_Creation_Date}
                        </span>
                      </div>
                      <Button
                        color='primary'
                        onClick={e => {
                          console.log('event', e)
                          e.stopPropagation()
                          setSelectedInspection(pdf.id)
                          setDialogOpen(false)
                          setJobDialogOpen(true)
                        }}
                        variant='contained'
                      >
                        Convert to Job <WorkIcon />
                      </Button>
                      <Button
                      /* Rennel Parino HPDS 10/27/2022 - Adding the button that allows viewing of PDFs directly from S3 Server */
                        color='primary'
                        onClick={e => {
                          console.log(pdf)
                          console.log(fetchCommand);
                          //window.open(pdf.fieldData.S3Location, '_self')
                          
                        }}
                        variant='contained'
                      >
                        Download PDF <WorkIcon />
                      </Button>
                    </ListItem>
                  ))
                : 'There are no inspections for this job'}
            </List>
            {errorMessage != '' && (
              <div style={{ color: 'red' }}>
                <br />
                {errorMessage}
              </div>
            )}
          </DialogContent>
        )}

        {loading && (
          <DialogContent className={classes.dialogContentSpacing}>
            <LinearProgress />
          </DialogContent>
        )}

        <DialogActions className={classes.generateAction}>
          <Button
            className={classes.cancel}
            onClick={handleCloseClick}
            variant='contained'
            color='primary'
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default PdfDialog
